import React, { useEffect, useState } from 'react';
import '../styles/Networking.css'; // Assuming you will create a separate CSS file
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

const Networking = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Networking'; // Set the new title
  }, []); // Runs only once when the component mounts

  return (
    <div className="networking-container">


{currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}



      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Unlock Your Professional Potential</h1>
          <p>Grow, Network, and Thrive with Boon</p>
          <a href="#get-started" className="cta-button">Start Networking Today</a>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="benefits-section">
        <h2 className="section-title">Key Benefits of Networking</h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <h3>Access to Hidden Job Markets</h3>
            <p>Networking opens doors to job opportunities that are not advertised publicly, giving you access to the hidden job market.</p>
            <p className="example">Example: John secured a role through a contact he met at a Boon event.</p>
          </div>
          <div className="benefit-item">
            <h3>Learning from Industry Leaders</h3>
            <p>Connect with professionals at different levels, gaining valuable insights and career guidance.</p>
            <p className="example">Example: Emily raised funding for her startup after meeting entrepreneurs at Boon events.</p>
          </div>
          <div className="benefit-item">
            <h3>Expanding Your Network Globally</h3>
            <p>Build a global network that extends beyond local boundaries, connecting with professionals worldwide.</p>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="success-stories-section">
        <h2 className="section-title">Real-World Success Stories</h2>
        <div className="success-story">
          <p>"Thanks to Boon, I connected with a mentor who guided me through the process of launching my startup!"</p>
          <p>- Sarah, Startup Founder</p>
        </div>
        <div className="success-story">
          <p>"I attended a networking event on Boon and ended up finding my current job through a connection I made."</p>
          <p>- Alex, Marketing Specialist</p>
        </div>
      </section>

      {/* How Networking Works Section */}
      <section className="how-it-works-section">
        <h2 className="section-title">How Networking Works on Boon</h2>
        <div className="how-it-works-grid">
          <div className="how-it-works-item">
            <h3>Virtual Events and Meetups</h3>
            <p>Participate in virtual events, meetups, workshops, and webinars to engage with professionals in your field.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Mentorship Programs</h3>
            <p>Connect with mentors who can provide career guidance and help you advance in your profession.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Direct Messaging & Collaboration</h3>
            <p>Message professionals directly, collaborate on projects, and build lasting relationships.</p>
          </div>
        </div>
      </section>

      {/* Bridging Gaps Section */}
      <section className="bridging-gaps-section">
        <h2 className="section-title">Bridging Gaps Between Creators, Followers, and Executives</h2>
        <div className="bridging-gaps-grid">
          <div className="gap-item">
            <h3>Creators and Followers</h3>
            <p>Boon allows creators to engage with followers, transforming passive communities into active, collaborative spaces.</p>
          </div>
          <div className="gap-item">
            <h3>Executives and Entry-Level Professionals</h3>
            <p>Executives can mentor and guide entry-level professionals, fostering career growth and new opportunities.</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section id="get-started" className="cta-section">
        <h2>Ready to Grow Your Network?</h2>
        <a href="/signup" className="cta-button">Join Boon Today</a>
      </section>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
};

export default Networking;
