import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Firebase Firestore instance
import { collection, getDocs } from 'firebase/firestore';
import '../styles/CommunitiesPage.css'; // Custom CSS for this component
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function CommunitiesPage() {
  const [communities, setCommunities] = useState([]); // Store the list of communities
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state to handle any errors

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Communities'; // Set the new title
  }, []); // Runs only once when the component mounts

  useEffect(() => {
    // Function to fetch community data from Firestore
    const fetchCommunities = async () => {
      setLoading(true); // Start loading
      setError(''); // Reset any previous error messages

      try {
        const communitiesRef = collection(db, 'communities');
        const snapshot = await getDocs(communitiesRef);
        const communitiesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCommunities(communitiesList);
      } catch (error) {
        console.error('Error fetching communities:', error);
        setError('Failed to load communities. Please try again later.');
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchCommunities();
  }, []);

  return (
    <div className="communities-page">
      <div>
      <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>

        <div className="header-right">
          <Link to='/messages' className="nav-link">
            <p>DMs</p>
          </Link>
          <Link to='/communities' className="nav-link">
            <p>Community</p>
          </Link>
          <Link to='/connections' className="nav-link">
            <p>Connect</p>
          </Link>

          {currentUser && (
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
          )}
        </div>
      </header>
      </div>
      
      <h1>Available Communities</h1>

      {/* Loading State */}
      {loading && <p>Loading communities...</p>}

      {/* Error State */}
      {error && <p className="error-message">{error}</p>}

      <div className="communities-list">
  {!loading && !error && communities.length > 0 ? (
    communities.map((community) => (
      <Link to={`/communities/${community.id}`} key={community.id} className="community-link">

      <div
        className="community-card"
        key={community.id}
        style={{
          backgroundImage: `url(${community.photo || '/default-community-image.jpg'})`, // Use a default image if no photo is available
          backgroundSize: 'cover', // Make sure the image covers the entire background
          backgroundPosition: 'center', // Center the background image
          backgroundRepeat: 'no-repeat', // Ensure the background image doesn't repeat
        }}
      >
        <div className="community-card-content">
          <h2>{community?.name}</h2>
          <p className="community-tags">
            {community.tags && community.tags.length > 0
              ? community.tags.map((tag, index) => (
                <span className="tag" key={index}>
                  {tag}
                </span>
              ))
              : 'No tags available'}
          </p>
          <p className="community-members">
            Members: {community.members ? community.members.length : '0'}
          </p>
        </div>
      </div>
      </Link>

    ))
  ) : (
    !loading && <p>No communities available.</p>
  )}
</div>
    <div>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <h2>Boon</h2>
            <p>Empowering community building and professional growth.</p>
          </div>

          <div className="footer-links">
            <div className="footer-column">
              <h3>Boon</h3>
              <a href="/about">About</a>
              <a href="/careers">Careers</a>
              {/* <a href="/blog">Blog</a> */}
            </div>
            <div className="footer-column">
              <h3>Resources</h3>
              <a href="/support">Support</a>
              <a href="/faq">FAQ</a>
              {/* <a href="/tutorials">Tutorials</a> */}
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 Boon. All Rights Reserved.</p>
          <div className="footer-bottom-links">
            <a href="/privacy">Privacy Policy</a>
            <a href="/tos">Terms of Service</a>
          </div>
          <div className="social-icons">
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
      </footer>
      </div>
    </div>
  );
}

export default CommunitiesPage;
