import React from 'react';
import '../styles/EditModal.css';

function EditModal({ title, onClose, children }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  );
}

export default EditModal;
