import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import '../styles/DirectMessages.css';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import debounce from 'lodash.debounce'; // Use debounce to optimize search requests

function DirectMessages() {
  const [messages, setMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [usersMap, setUsersMap] = useState({});

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Direct Messages'; // Set the new title
  }, []);

  // Fetch users and create a mapping of user ID to user details (photo, username, firstName)
  const fetchUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersMap = {};
      usersSnapshot.forEach((doc) => {
        usersMap[doc.id] = doc.data();
      });
      setUsersMap(usersMap); // Store the user data
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const fetchMessages = async () => {
    try {
      if (!currentUser) return;

      // Query for messages where the current user is the receiver
      const toUserQuery = query(
        collection(db, 'directMessages'),
        where('toUser', '==', currentUser.uid)
      );

      // Query for messages where the current user is the sender
      const fromUserQuery = query(
        collection(db, 'directMessages'),
        where('fromUser', '==', currentUser.uid)
      );

      // Execute both queries in parallel
      const [toUserSnapshot, fromUserSnapshot] = await Promise.all([
        getDocs(toUserQuery),
        getDocs(fromUserQuery)
      ]);

      // Combine the results from both queries
      const messagesList = [
        ...toUserSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...fromUserSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      ];

      // Group messages by the other user (who is not the current user)
      const groupedMessages = messagesList.reduce((groups, message) => {
        const otherUser = message.fromUser === currentUser.uid ? message.toUser : message.fromUser;
        if (!groups[otherUser]) {
          groups[otherUser] = [];
        }
        groups[otherUser].push(message);
        return groups;
      }, {});

      setMessages(groupedMessages); // Set the grouped messages
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  fetchMessages();
  // Fetch direct messages for the current user and group by sender
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        if (!currentUser) return;

        // Query for messages where the current user is the receiver
        const toUserQuery = query(
          collection(db, 'directMessages'),
          where('toUser', '==', currentUser.uid)
        );

        // Query for messages where the current user is the sender
        const fromUserQuery = query(
          collection(db, 'directMessages'),
          where('fromUser', '==', currentUser.uid)
        );

        // Execute both queries in parallel
        const [toUserSnapshot, fromUserSnapshot] = await Promise.all([
          getDocs(toUserQuery),
          getDocs(fromUserQuery)
        ]);

        // Combine the results from both queries
        const messagesList = [
          ...toUserSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...fromUserSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        ];

        // Group messages by the other user (who is not the current user)
        const groupedMessages = messagesList.reduce((groups, message) => {
          const otherUser = message.fromUser === currentUser.uid ? message.toUser : message.fromUser;
          if (!groups[otherUser]) {
            groups[otherUser] = [];
          }
          groups[otherUser].push(message);
          return groups;
        }, {});

        setMessages(groupedMessages); // Set the grouped messages
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [currentUser]);

  // Real-time search with debounce
  useEffect(() => {
    const debouncedSearch = debounce(async () => {
      if (!searchQuery) {
        setSearchResults([]);
        return;
      }

      try {
        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          where('username', '>=', searchQuery),
          where('username', '<=', searchQuery + '\uf8ff') // For partial matching
        );

        const snapshot = await getDocs(q);
        const usersList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSearchResults(usersList);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    }, 300); // Debounce delay of 300ms

    debouncedSearch();

    return () => {
      debouncedSearch.cancel(); // Cleanup debounce on unmount
    };
  }, [searchQuery]);

  // Handle sending a new message to the selected user
  const sendMessage = async () => {
    if (!newMessage || !selectedUser) return;

    try {
      const messagesRef = collection(db, 'directMessages');
      await addDoc(messagesRef, {
        fromUser: currentUser.uid,
        toUser: selectedUser.id,
        message: newMessage,
        timestamp: new Date(),
      });

      setNewMessage('');
      setSelectedUser(null);
      fetchMessages();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="direct-messages-container">
      <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>

        <div className="header-right">
          <Link to='/messages' className="nav-link">
            <p>DMs</p>
          </Link>
          <Link to='/communities' className="nav-link">
            <p>Community</p>
          </Link>
          <Link to='/connections' className="nav-link">
            <p>Connect</p>
          </Link>

          {currentUser && (
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
          )}
        </div>
      </header>

      <h1>Direct Messages</h1>

      {/* Search bar for users */}
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search users by first name or username"
        />
      </div>

      {/* Display search results */}
      {searchResults.length > 0 && (
        <div className="search-results">
          <h2>Search Results</h2>
          <ul>
            {searchResults.map((user) => (
              <li key={user.id} className="user-item" onClick={() => setSelectedUser(user)}>
                <img src={user.photo || '/default-user.png'} alt={`${user.firstName} ${user.lastName}`} className="user-photo" />
                <div className="user-details">
                  <p>{user.firstName} {user.lastName}</p>
                  <p>@{user.username}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Grouped messages by sender */}
      <div className="grouped-messages">
        <h2>DMss</h2>
        {Object.keys(messages).map((userId) => (
          <div key={userId} className="message-group">
            <div className="user-header">
              <img src={usersMap[userId]?.photo || '/default-user.png'} alt={`${usersMap[userId]?.firstName}`} className="user-photo" />
              <h3>{usersMap[userId]?.firstName} {usersMap[userId]?.lastName} (@{usersMap[userId]?.username})</h3>
            </div>
            {messages[userId].map((message) => (
              <div key={message.id} className="message-card">
                <p>{message.message}</p>
                <p>Sent: {new Date(message.timestamp?.toDate()).toLocaleString()}</p>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* New message form */}
      {selectedUser && (
        <div className="new-message-form">
          <h3>Send a message to {selectedUser.firstName} ({selectedUser.username})</h3>
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Write your message..."
          />
          <button onClick={sendMessage}>Send Message</button>
        </div>
      )}

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <h2>Boon</h2>
            <p>Empowering community building and professional growth.</p>
          </div>

          <div className="footer-links">
            <div className="footer-column">
              <h3>Boon</h3>
              <a href="/about">About</a>
              <a href="/careers">Careers</a>
            </div>
            <div className="footer-column">
              <h3>Resources</h3>
              <a href="/support">Support</a>
              <a href="/faq">FAQ</a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 Boon. All Rights Reserved.</p>
          <div className="footer-bottom-links">
            <a href="/privacy">Privacy Policy</a>
            <a href="/tos">Terms of Service</a>
          </div>
          <div className="social-icons">
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default DirectMessages;
