import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import '../styles/UserProfile.css';
import { Link } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import storage functions
import { useNavigate } from 'react-router-dom';   // React Router for navigation


function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [newInterest, setNewInterest] = useState('');
  const [loading, setLoading] = useState(true);
  const [profileCompleteness, setProfileCompleteness] = useState(0);

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();   // React Router's navigation hook

  const calculateProfileCompleteness = (data) => {
    const totalFields = Object.keys(data).length;
    const filledFields = Object.values(data).filter((value) => value !== '').length;
    const percentage = Math.round((filledFields / totalFields) * 100);
    setProfileCompleteness(percentage);
  };

    // Function to handle logout
    const handleLogout = async () => {
      try {
        await signOut(auth);          // Sign the user out
        navigate('/login');           // Redirect to the login page
      } catch (error) {
        console.error("Error logging out:", error); // Handle errors here
      }
    };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const filteredData = {
            fullName: `${userData.firstName || ''} ${userData.lastName || ''}`,
            email: userData.email || '',
            username: userData.username || '',
            position: userData.position || '',
            company: userData.company || '',
            yearsOfExperience: userData.yearsOfExperience || '',
            seeking: userData.seeking || '',
            bio: userData.bio || '',
            interests: userData.interests || [],
            photo: userData.photo || ''
          };
          setUserData(filteredData);
          setFormData(filteredData);
          calculateProfileCompleteness(filteredData);
        } else {
          console.error('User document not found');
        }
      } catch (error) {
        console.error('Error fetching user data: ', error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        photo: URL.createObjectURL(file),
      });
    }
  };

  const handleSave = async () => {
    try {
      // Ensure you have the UID of the current user
      const userId = currentUser.uid;
  
      // Split the full name into first and last name if needed
      const [firstName = '', lastName = ''] = formData.fullName.split(' ');
  
      // Check if a new photo has been provided
      let photoURL = userData.photo;
  
      if (formData.photo && formData.photo !== userData.photo) {
        // Create a storage reference in Firebase without manually using storageRef
        const storage = getStorage();
        
        // Instead of creating a custom path with ref, Firebase manages the storage references based on the user's UID and photo path
        const photoFile = formData.photo;
  
        // Upload the photo (assumes `formData.photo` is a File object from an <input> tag)
        const storageRef = ref(storage, `users/${userId}/profilePhoto`); // This path is automatically created
        const snapshot = await uploadBytes(storageRef, photoFile);
  
        // Get the download URL of the uploaded photo
        photoURL = await getDownloadURL(snapshot.ref);  // This retrieves the URL from the uploaded photo
      }
  
      // Prepare the updated user data, including the uploaded photo URL
      const updatedUserData = {
        firstName,
        lastName,
        email: formData.email,
        username: formData.username,
        position: formData.position,
        company: formData.company,
        yearsOfExperience: formData.yearsOfExperience,
        bio: formData.bio,
        interests: formData.interests,
        photo: photoURL, // Use the new photo URL or existing one
      };
  
      // Get the reference to the user's document in Firestore
      const userDocRef = doc(db, 'users', userId);
  
      // Update the document with the new data
      await updateDoc(userDocRef, updatedUserData);
  
      // Update local state with the new data
      setUserData(updatedUserData);
      setEditMode(false); // Exit edit mode after saving
  
      // Recalculate profile completeness based on updated data
      calculateProfileCompleteness(updatedUserData);
  
    } catch (error) {
      console.error('Error updating user data: ', error);
    }
  };

  const addInterest = () => {
    if (newInterest.trim()) {
      setFormData({
        ...formData,
        interests: [...formData.interests, newInterest.trim()],
      });
      setNewInterest('');
    }
  };

  const removeInterest = (index) => {
    const updatedInterests = formData.interests.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      interests: updatedInterests,
    });
  };

  if (loading) return <p>Loading user data...</p>;

  return (
    <div>
      {/* Profile completeness bar */}
      <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>


      <div className="user-profile-container">
          {/* Explanation Section */}
        <div className="profile-explanation">
          <p>
            The more details you add to your profile, the better we can enhance your networking
            and connection experience. Including more interests and professional information helps
            us match you with like-minded individuals and provide more relevant opportunities. 
            Be sure to include as many details and interests as possible!
          </p>
        </div>


        {userData && (
          <>
          

         <div className="profile-completeness-container">
        <p className="profile-completeness-text">{profileCompleteness}% Profile Complete</p>
        
        <div className="profile-completeness-bar">
          <div
            className="progress"
            style={{ width: `${profileCompleteness}%` }}
          ></div>
        </div>
      </div>


            {/* User Profile Section */}
            <div className="profile-header">
              {/* Clickable user photo */}
              <label htmlFor="photo-upload">
                <img
                  src={userData.photo || '/default-profile.png'}
                  alt={`${userData.fullName}'s profile`}
                  className="profile-photo"
                  style={{ cursor: 'pointer' }}
                />
              </label>
              <input
                type="file"
                id="photo-upload"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />

              <h1>
                {editMode ? (
                  <>
                    <p>Full Name:</p>
                    <input
                      type="text"
                      name="fullName"
                      value={formData.fullName || ''}
                      onChange={handleChange}
                      placeholder="Full Name"
                    />
                    <p>Email:</p>
                    <input
                      type="email"
                      name="email"
                      value={formData.email || ''}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                    <p>Username:</p>
                    <input
                      type="text"
                      name="username"
                      value={formData.username || ''}
                      onChange={handleChange}
                      placeholder="Username"
                    />
                    <p>Position:</p>
                    <input
                      type="text"
                      name="position"
                      value={formData.position || ''}
                      onChange={handleChange}
                      placeholder="Position"
                    />
                    <p>Company:</p>
                    <input
                      type="text"
                      name="company"
                      value={formData.company || ''}
                      onChange={handleChange}
                      placeholder="Company"
                    />
                    <p>Years of Experience:</p>
                    <input
                      type="number"
                      name="yearsOfExperience"
                      value={formData.yearsOfExperience || ''}
                      onChange={handleChange}
                      placeholder="Years of Experience"
                    />
                    <p>Bio:</p>
                    <input
                      type="text"
                      name="bio"
                      value={formData.bio || ''}
                      onChange={handleChange}
                      placeholder="Tell us about yourself"
                    />
                    <p>Interests:</p>
                    <div className="interests-container">
                      {formData.interests.map((interest, index) => (
                        <div key={index} className="interest-tag">
                          <p className="interest-text">{interest}
                            <span
                              className="remove-interest"
                              onClick={() => removeInterest(index)}
                            >
                              &times;
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                    <input
                      type="text"
                      value={newInterest}
                      onChange={(e) => setNewInterest(e.target.value)}
                      placeholder="Add new interest"
                    />
                    <button type="button" onClick={addInterest}>+ Add Interest</button>
                  </>
                ) : (
                  <>
                    <div className="profile-info-container">
                      <p>Full Name: {userData.fullName}</p>
                      <p>Email: {userData.email || 'Not provided'}</p>
                      <p>Username: {userData.username || 'Not provided'}</p>
                      <p>Position: {userData.position || 'Not provided'}</p>
                      <p>Company: {userData.company || 'Not provided'}</p>
                      <p>Years of Experience: {userData.yearsOfExperience || 'Not provided'}</p>
                      <p>Bio: {userData.bio || 'No bio available'}</p>
                      <p>Interests:</p>
                      <ul>
                        {userData.interests.map((interest, index) => (
                          <li key={index}>{interest}</li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </h1>

              {editMode ? (
                <div>
                  <button onClick={handleSave}>Save Changes</button>
                  <button onClick={() => setEditMode(false)}>Cancel</button>
                </div>
              ) : (
                <button onClick={() => setEditMode(true)}>Edit Profile</button>
              )}
                  <button onClick={handleLogout} className="logout-button">
                  Logout
                </button>
              
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
