import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase'; // Assuming Firebase is initialized
import { collection, getDocs, query, where } from 'firebase/firestore'; // Firestore functions
import '../styles/Connections.css'; // Custom CSS for this component

import { getAuth } from 'firebase/auth'; // To get the authenticated user

function Connections() {
  const [users, setUsers] = useState([]);
  const [recommendedUsers, setRecommendedUsers] = useState([]);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Connections'; // Set the new title
  }, []); // Runs only once when the component mounts

  // Fetch users data from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const snapshot = await getDocs(usersCollection);
        const usersList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
        return;
      }
    };

    fetchUsers();
  }, []);

// Helper function to calculate shared interests with error handling
const calculateSharedInterests = (user1, user2) => {
  try {
    // Ensure both users have interests defined and they are arrays, else return a minimal score
    if (!Array.isArray(user1?.interests) || !Array.isArray(user2?.interests)) {
      return 0.001; // Return 0.001 if interests are undefined or not arrays
    }

    // Filter for common interests
    const commonInterests = user1.interests.filter(interest =>
      user2.interests.includes(interest)
    );
    const percentage = (commonInterests.length / user1.interests.length) * 100;

    return percentage;
  } catch (error) {
    console.error("Error calculating shared interests:", error);
    return 0.001; // Return 0.001 in case of any error
  }
};




// Helper function to calculate match score based on position, experience, and shared interests
const calculateMatchScore = (user1, user2) => {
  try {
    // Assign default match score of 0.001 if any essential details are missing
    if (!user1 || !user2 || !user1.position || !user2.position || !user1.yearsOfExperience || !user2.yearsOfExperience) {
      return 0.001; // Return 0.001 if any user details are missing
    }

    const positionMatch = user1.position === user2.position ? 50 : 0;

    // Handle missing or undefined yearsOfExperience
    const experienceDifference = Math.abs((user1.yearsOfExperience || 0) - (user2.yearsOfExperience || 0));
    const experienceScore = Math.max(0, 50 - experienceDifference * 5); // Penalize if experience difference is large

    const sharedInterestsPercentage = calculateSharedInterests(user1, user2);

    return positionMatch + experienceScore + sharedInterestsPercentage;
  } catch (error) {
    console.error("Error calculating match score:", error);
    return 0.001; // Return 0.001 in case of any error
  }
};


// / Get top 5 recommended users
useEffect(() => {
  try {
    // Check if currentUser exists and users have been loaded
    if (currentUser && users.length > 0) {
      const validConnections = users.filter(user => user.uid !== currentUser.uid);

      // Calculate match scores for each user
      const connectionsWithScores = validConnections.map(user => {
        try {
          const matchScore = calculateMatchScore(currentUser, user);
          return {
            ...user,
            matchScore,
          };
        } catch (error) {
          console.error("Error calculating match score for user:", user, error);
          return { ...user, matchScore: 0.001 }; // Assign a fallback score if error occurs
        }
      });

      // Sort by match score and get the top 5
      const sortedConnections = connectionsWithScores
        .sort((a, b) => b.matchScore - a.matchScore)
        .slice(0, 5);

      // If no strong matches are found, fall back to interests only
      if (sortedConnections.length === 0) {
        const interestOnlyConnections = validConnections.map(user => {
          try {
            return {
              ...user,
              sharedPercentage: calculateSharedInterests(currentUser, user),
            };
          } catch (error) {
            console.error("Error calculating shared interests for user:", user, error);
            return { ...user, sharedPercentage: 0.001 }; // Fallback value
          }
        });

        setRecommendedUsers(
          interestOnlyConnections
            .sort((a, b) => b.sharedPercentage - a.sharedPercentage)
            .slice(0, 5)
        );
      } else {
        setRecommendedUsers(sortedConnections);
      }
    }
  } catch (error) {
    console.error("Error fetching and processing recommended users:", error);
    setRecommendedUsers([]); // Optionally reset or set a fallback value for recommended users
  }
}, [currentUser, users]);


  return (
    <div className="connections-container">
      {currentUser?.uid ? (
        <header className="header">
          <div className="header-left">
            <h1 className="company-name">Boon</h1>
          </div>

          <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>

            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
          </div>
        </header>
      ) : (
        <header className="header">
          <div className="header-left">
            <Link to="/" className="company-link">
              <h1 className="company-name">Boon</h1>
            </Link>
          </div>
          <div className="header-right">
            <Link to="/login" className="nav-link">Join</Link>
          </div>
        </header>
      )}

      <h1>Recommended Connections</h1>
      <div className="connections-list">
      {recommendedUsers.length > 0 ? (
  recommendedUsers.map(user => (
    <div key={user.id} className="connection-card">
      {/* User photo and bio centered at the top */}
      <div className="user-info">
        {user.photo && (
          <img 
            src={user.photo} 
            alt={`${user.name}'s profile`} 
            className="user-photo" 
          />
        )}
        {user.bio && <p className="user-bio">{user.bio}</p>}
      </div>

      <h2>{user.username}</h2>
      <p>Position: {user.position}</p>
      <p>Years of Experience: {user.yearsOfExperience}</p>
      <p>Match Score: {user.matchScore.toFixed(2)}</p>

      {/* Conditionally render common interests if the user has interests */}
      {Array.isArray(user.interests) && user.interests.length > 0 && (
        <div>
          <p>Common Interests:</p>
          <ul>
            {user.interests
              .filter(interest => currentUser?.interests?.includes(interest))
              .map((interest, index) => (
                <li key={index}>{interest}</li>
              ))}
          </ul>
        </div>
      )}

      <Link to={`/invite/${user.id}`} className="invite-button">
        Invite to Connect
      </Link>
    </div>
  ))
) : (
  <p>No recommended connections found.</p>
)}

      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <h2>Boon</h2>
            <p>Empowering community building and professional growth.</p>
          </div>

          <div className="footer-links">
            <div className="footer-column">
              <h3>Boon</h3>
              <a href="/about">About</a>
              <a href="/careers">Careers</a>
            </div>
            <div className="footer-column">
              <h3>Resources</h3>
              <a href="/support">Support</a>
              <a href="/faq">FAQ</a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 Boon. All Rights Reserved.</p>
          <div className="footer-bottom-links">
            <a href="/privacy">Privacy Policy</a>
            <a href="/tos">Terms of Service</a>
          </div>
          <div className="social-icons">
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Connections;
