import React, { useEffect, useState } from 'react';
import { db, storage } from '../firebase'; // Firestore and Firebase Storage imports
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import '../styles/CreateCommunity.css'; // Import custom styles
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user


function CreateCommunity({ currentUserId }) {
  const [communityName, setCommunityName] = useState('');
  const [bio, setBio] = useState('');
  const [memberLimit, setMemberLimit] = useState('');
  const [tags, setTags] = useState('');
  const [notes, setNotes] = useState('');
  const [guidelines, setGuidelines] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoUrl, setPhotoUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;



  useEffect(() => {
    document.title = 'Create a Community'; // Set the new title
  }, []); // Runs only once when the component mounts



  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `community-photos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // You can track upload progress here if needed
        },
        (error) => {
          console.error('Error uploading image:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setPhotoUrl(downloadURL);
        }
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Save community data to Firestore
      await addDoc(collection(db, 'communities'), {
        name: communityName,
        bio,
        memberLimit: parseInt(memberLimit, 10),
        tags: tags.split(',').map((tag) => tag.trim()), // Convert comma-separated string to array
        notes,
        guidelines,
        photoUrl,
        createdBy: currentUserId,
        createdAt: new Date(),
        members: [currentUserId], // Add the creator as the first member
      });

      setLoading(false);
      alert('Community created successfully!');
      // Optionally redirect the user to the community's page
    } catch (error) {
      console.error('Error creating community:', error);
      setLoading(false);
    }
  };

  return (
    <div className="create-community-container">

    <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>

        <div className="header-right">
        <Link to='/messages' className="nav-link">
          <p>DMs</p>
        </Link>
        <Link to='/communities' className="nav-link">
          <p>Community</p>
        </Link>
        <Link to='/connections' className="nav-link">
          <p>Connect</p>
        </Link>

        <Link to={`/profile/${currentUser.uid}`} className="nav-link">
          <p>Profile</p>
        </Link>
        </div>
      </header>


      <h1>Create a New Community</h1>
      <form onSubmit={handleSubmit} className="create-community-form">
        {/* Community Name */}
        <input
          type="text"
          placeholder="Community Name"
          value={communityName}
          onChange={(e) => setCommunityName(e.target.value)}
          required
        />

        {/* Community Bio */}
        <textarea
          placeholder="Community Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          required
        />

        {/* Member Limit */}
        <input
          type="number"
          placeholder="Member Limit"
          value={memberLimit}
          onChange={(e) => setMemberLimit(e.target.value)}
          required
        />

        {/* Tags */}
        <input
          type="text"
          placeholder="Tags (comma separated)"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />

        {/* Community Notes */}
        <textarea
          placeholder="Community Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />

        {/* Guidelines */}
        <textarea
          placeholder="Community Guidelines"
          value={guidelines}
          onChange={(e) => setGuidelines(e.target.value)}
        />

        {/* Community Photo */}
        <input type="file" onChange={handleImageUpload} />
        {photoUrl && <img src={photoUrl} alt="Community" className="image-preview" />}

        {/* Submit Button */}
        <button type="submit" disabled={loading}>
          {loading ? 'Creating Community...' : 'Create Community'}
        </button>
      </form>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>



    </div>
  );
}

export default CreateCommunity;
