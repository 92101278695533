import React from 'react';
import Sketch from 'react-p5';

function HeroNetwork() {
  let particles = [];

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(canvasParentRef);

    // Create 100 particles
    for (let i = 0; i < 100; i++) {
      particles.push(new Particle(p5));
    }
  };

  const draw = (p5) => {
    p5.background(30);

    // Draw and update each particle
    particles.forEach((particle, index) => {
      particle.update();
      particle.show();
      particle.connect(particles);
    });
  };

  // Particle class representing "people" in the network
  class Particle {
    constructor(p5) {
      this.p5 = p5;
      this.pos = p5.createVector(p5.random(p5.width), p5.random(p5.height));
      this.vel = p5.createVector(p5.random(-1, 1), p5.random(-1, 1));
      this.size = 8;
    }

    update() {
      this.pos.add(this.vel);

      // Bounce off edges
      if (this.pos.x > this.p5.width || this.pos.x < 0) {
        this.vel.x *= -1;
      }
      if (this.pos.y > this.p5.height || this.pos.y < 0) {
        this.vel.y *= -1;
      }
    }

    show() {
      this.p5.noStroke();
      this.p5.fill(200, 100, 255);
      this.p5.circle(this.pos.x, this.pos.y, this.size);
    }

    connect(particles) {
      particles.forEach(particle => {
        let distance = this.p5.dist(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y);
        if (distance < 150) {
          this.p5.stroke(255, 150);
          this.p5.line(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y);
        }
      });
    }
  }

  return (
    <div className="hero-network">
      <Sketch setup={setup} draw={draw} />
    </div>
  );
}

export default HeroNetwork;
