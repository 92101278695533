import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import '../styles/UserDashboard.css'; 
import { getAuth } from 'firebase/auth'; // To get the authenticated user


function UserDashboard({ userId }) {
  const [attendedEvents, setAttendedEvents] = useState([]);
  const [createdEvents, setCreatedEvents] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [communities, setCommunities] = useState([]);
  const [createdCommunities, setCreatedCommunities] = useState([]);
  const [activeTab, setActiveTab] = useState('createdEvents'); // New state for active tab


  useEffect(() => {
    document.title = 'Dashboard'; // Set the new title
  }, []); // Runs only once when the component mounts



  useEffect(() => {
    // Fetch user profile details
    const fetchUserProfile = async () => {
      try {
        const profileRef = collection(db, 'users');
        const userQuery = query(profileRef, where('uid', '==', userId));
        const profileSnapshot = await getDocs(userQuery);
        if (!profileSnapshot.empty) {
          setUserProfile(profileSnapshot.docs[0].data());
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    // Fetch attended events
    const fetchAttendedEvents = async () => {
      try {
        const eventsRef = collection(db, 'events');
        const eventsQuery = query(eventsRef, where('attendees', 'array-contains', userId));
        const eventsSnapshot = await getDocs(eventsQuery);

        console.log('Attended events fetched:', eventsSnapshot.docs.length);

        if (!eventsSnapshot.empty) {
          setAttendedEvents(eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        }
      } catch (error) {
        console.error('Error fetching attended events:', error);
      }
    };

    // Fetch created events (where the user is the owner)
    const fetchCreatedEvents = async () => {
      try {
        const eventsRef = collection(db, 'events');
        const createdEventsQuery = query(eventsRef, where('uid', '==', userId));
        const createdEventsSnapshot = await getDocs(createdEventsQuery);

        if (!createdEventsSnapshot.empty) {
          setCreatedEvents(createdEventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        }
      } catch (error) {
        console.error('Error fetching created events:', error);
      }
    };

    // Fetch user tickets
    const fetchUserTickets = async () => {
      try {
        const ticketsRef = collection(db, 'tickets');
        const ticketsQuery = query(ticketsRef, where('userId', '==', userId));
        const ticketsSnapshot = await getDocs(ticketsQuery);
        setTickets(ticketsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    // Fetch user communities
    const fetchUserCommunities = async () => {
      try {
        const communitiesRef = collection(db, 'communities');
        const communitiesQuery = query(communitiesRef, where('members', 'array-contains', userId));
        const communitiesSnapshot = await getDocs(communitiesQuery);
        setCommunities(communitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching communities:', error);
      }
    };

    // Fetch communities created by the user
    const fetchCreatedCommunities = async () => {
      try {
        const communitiesRef = collection(db, 'communities');
        const createdCommunitiesQuery = query(communitiesRef, where('communityOwner', '==', userId));
        const createdCommunitiesSnapshot = await getDocs(createdCommunitiesQuery);

        if (!createdCommunitiesSnapshot.empty) {
          setCreatedCommunities(createdCommunitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        }
      } catch (error) {
        console.error('Error fetching created communities:', error);
      }
    };

    fetchUserProfile();
    fetchAttendedEvents();
    fetchCreatedEvents(); // Fetch events created by the user
    fetchUserTickets();
    fetchUserCommunities();
    fetchCreatedCommunities(); // Fetch communities created by the user
  }, [userId]);

   // Function to render the current active tab
   const renderActiveTab = () => {
    switch (activeTab) {
      case 'createdEvents':
        return renderCreatedEvents();
      case 'attendedEvents':
        return renderAttendedEvents();
      case 'tickets':
        return renderTickets();
      case 'communities':
        return renderCommunities();
      case 'createdCommunities':
        return renderCreatedCommunities();
      default:
        return renderCreatedEvents();
    }
  };

  const renderCreatedEvents = () => (
    <div className="section-content">
      <h2>Events You've Created</h2>
      {createdEvents.length > 0 ? (
        createdEvents.map(event => (
          <div key={event.id} className="card"
            style={{
              backgroundImage: `url(${event.postPhoto || '/default-event-photo.jpg'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}>
            <div className="card-overlay">
              <Link to={`/event/${event.id}`} className="event-link">
                <h3>{event.postDescription}</h3>
                <p>{event.eventBio}</p>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <p>No events created yet.</p>
      )}
    </div>
  );
  
  const renderAttendedEvents = () => (
    <div className="section-content">
      <h2>Attended Events</h2>
      {attendedEvents.length > 0 ? (
        attendedEvents.map(event => (
          <div key={event.id} className="card"
            style={{
              backgroundImage: `url(${event.postPhoto || '/default-event-photo.jpg'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}>
            <div className="card-overlay">
              <Link to={`/event/${event.id}`} className="event-link">
                <h3>{event.postDescription}</h3>
                <p>{event.eventBio}</p>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <p>No attended events yet.</p>
      )}
    </div>
  );
  
  const renderTickets = () => (
    <div className="section-content">
      <h2>Your Tickets</h2>
      {tickets.length > 0 ? (
        tickets.map(ticket => (
          <div key={ticket.id} className="card"
            style={{
              backgroundImage: `url(${ticket.postPhoto || '/default-ticket-photo.jpg'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}>
            <div className="card-overlay">
              <p>Event: {ticket.eventName}</p>
              <Link to={`/ticket/${ticket.id}`} className="ticket-link">View Ticket</Link>
            </div>
          </div>
        ))
      ) : (
        <p>No tickets found.</p>
      )}
    </div>
  );
  
  const renderCommunities = () => (
    <div className="section-content">
      <h2>Your Communities</h2>
      {communities.length > 0 ? (
        communities.map(community => (
          <div key={community.id} className="card"
            style={{
              backgroundImage: `url(${community.photo || '/default-community-photo.jpg'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}>
            <div className="card-overlay">
              <Link to={`/community/${community.id}`} className="community-link">
                <h3>{community.name}</h3>
                <p>{community.description}</p>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <p>No communities joined yet.</p>
      )}
    </div>
  );
  
  const renderCreatedCommunities = () => (
    <div className="section-content">
      <h2>Communities You've Created</h2>
      {createdCommunities.length > 0 ? (
        createdCommunities.map(community => (
          <div key={community.id} className="card"
            style={{
              backgroundImage: `url(${community.photo || '/default-community-photo.jpg'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}>
            <div className="card-overlay">
              <Link to={`/community/${community.id}`} className="community-link">
                <h3>{community.name}</h3>
                <p>{community.description}</p>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <p>No communities created yet.</p>
      )}
    </div>
  );
        
  return (
    <div className="dashboard-container">
      {/* Header Section */}
      <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>

        <div className="header-right">
        <Link to='/messages' className="nav-link">
          <p>DMs</p>
        </Link>
        <Link to='/communities' className="nav-link">
          <p>Community</p>
        </Link>
        <Link to='/connections' className="nav-link">
          <p>Connect</p>
        </Link>

        <Link to={`/userdetails/${userId}`} className="nav-link">
          <p>My Details</p>
          </Link>
          </div>
        </header>


      {/* Profile Section */}
      {userProfile && (
        <div className="profile-section">
          <img src={userProfile.photo || '/default-avatar.png'} alt="Profile" className="profile-photo" />
          <h2>{userProfile.username}</h2>
          <p>{userProfile.bio}</p>
          {/* <Link to={`/profile/${userId}`} className="edit-profile-button">View Full Profile</Link> */}
        </div>
      )}



      <div className="tabs">
        <button onClick={() => setActiveTab('createdEvents')}>Created Events</button>
        <button onClick={() => setActiveTab('attendedEvents')}>Attended Events</button>
        <button onClick={() => setActiveTab('tickets')}>Tickets</button>
        <button onClick={() => setActiveTab('communities')}>Communities</button>
        <button onClick={() => setActiveTab('createdCommunities')}>Created Communities</button>
      </div>

      <div className="tab-content">
        {renderActiveTab()}
      </div>

      {/* Created Communities Section */}
    
      <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>
    </div>
  );
}

export default UserDashboard;
