import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase'; // Assuming Firebase is initialized
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore'; // Firestore functions
import '../styles/Home.css'; // Assuming the styles file exists
import heroImage from '../assets/images/hero-background.jpg';
import brunch_event from '../assets/images/brunch_event.png';
import cocktail_event from '../assets/images/cocktail_event.png';
import flower_event from '../assets/images/flower_event.png';
import summer_fest from '../assets/images/summer_fest.png';
import wellness_event from '../assets/images/wellness_event.png';
import { getAuth } from 'firebase/auth'; // To get the authenticated user
import gravelImage from '../assets/images/gravel-background.jpg';

import Sketch from 'react-p5';

function Home() {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0); // State for slideshow
  const [eventAppear, setEventAppear] = useState([]); // Track which events have appeared
  const images = [heroImage, gravelImage];
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Define isMenuOpen state
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    document.title = 'BOON'; // Set the new title
  }, []); // Runs only once when the component mounts
  const faqData = [
    {
      question: "What is Boon?",
      answer: "Boon is a platform designed to help users discover, attend, and create events while expanding their professional and social networks."
    },
    {
      question: "How do I create an event on Boon?",
      answer: "Simply sign in to your account, go to the 'Create Event' page, and fill in the event details. Your event will be published for others to view and join."
    },
    {
      question: "Is Boon free to use?",
      answer: "Yes, Boon offers free access to essential features for event discovery and creation. We also offer premium features for additional services."
    },
    {
      question: "Can I invite people to my event?",
      answer: "Yes! Once you've created your event, you can invite attendees via email or share the event link directly with them."
    },
    {
      question: "How do I find events near me?",
      answer: "You can browse events by location, category, and date on the 'Events' page. Use filters to refine your search to suit your preferences."
    },
    {
      question: "Can I charge for tickets to my event?",
      answer: "Yes, Boon allows you to set ticket prices for your event and manage ticket sales directly through the platform."
    },
    {
      question: "How do I manage my event?",
      answer: "Once an event is created, you can manage it from your profile, where you can update details, track attendees, and handle ticket sales."
    },
    {
      question: "Can I collaborate with others to organize an event?",
      answer: "Absolutely! You can invite co-organizers who will have access to help you manage the event."
    },
    {
      question: "What kind of events can I find on Boon?",
      answer: "Boon features a wide variety of events, including professional networking, social gatherings, workshops, and community meetups."
    },
    {
      question: "How can I contact support?",
      answer: "If you have any questions or need help, you can reach us through the 'Contact Us' page or email support@boon.com."
    }
  ];



  const eventsData = [
    {
      eventName: 'Professional Networking Meetup',
      eventDate: 'October 12, 2024',
      hostName: 'John Doe',
      locationName: 'New York, NY',
      backgroundImage: brunch_event, // Add the image path here
    },
    {
      eventName: 'Brunch & Networking for Professionals',
      eventDate: 'October 18, 2024',
      hostName: 'Jane Smith',
      locationName: 'Los Angeles, CA',
      backgroundImage: cocktail_event, // Add the image path here
    },
    {
      eventName: 'Workshop: Building Your Personal Brand',
      eventDate: 'October 25, 2024',
      hostName: 'Emily Davis',
      locationName: 'Chicago, IL',
      backgroundImage: flower_event, // Add the image path here
    },
    {
      eventName: 'Cocktail Meetups for Professionals',
      eventDate: 'November 2, 2024',
      hostName: 'Michael Lee',
      locationName: 'Miami, FL',
      backgroundImage: summer_fest, // Add the image path here
    },
    {
      eventName: 'Cocktail Meetups for Professionals',
      eventDate: 'November 2, 2024',
      hostName: 'Michael Lee',
      locationName: 'Miami, FL',
      backgroundImage: wellness_event, // Add the image path here
    },
  ];
  
  const [particles, setParticles] = useState([]);
  const canvasRef = useRef(null);
  const p5InstanceRef = useRef(null);


  const setup = (p5, canvasParentRef) => {
    const canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
    canvasRef.current = canvas;
    
    // Create 100 particles
    const newParticles = Array(100).fill().map(() => new Particle(p5));
    setParticles(newParticles);
  };

  const draw = (p5) => {
    p5.clear();
    
    particles.forEach((particle) => {
      particle.update();
      particle.show();
      particle.connect(particles);
    });
  };

  const windowResized = (p5) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
  };

  class Particle {
    constructor(p5) {
      this.p5 = p5;
      this.pos = p5.createVector(p5.random(p5.width), p5.random(p5.height));
      this.vel = p5.createVector(p5.random(-1, 1), p5.random(-1, 1));
      this.size = 4; // Reduced size for subtlety
    }

    update() {
      this.pos.add(this.vel);

      if (this.pos.x > this.p5.width || this.pos.x < 0) {
        this.vel.x *= -1;
      }
      if (this.pos.y > this.p5.height || this.pos.y < 0) {
        this.vel.y *= -1;
      }
    }

    show() {
      this.p5.noStroke();
      this.p5.fill(80); // Dark gray for particles
      this.p5.circle(this.pos.x, this.pos.y, this.size);
    }

    connect(particles) {
      particles.forEach(particle => {
        let distance = this.p5.dist(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y);
        if (distance < 120) {
          this.p5.stroke(255, 150);
          this.p5.line(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y);
        }
      });
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (p5InstanceRef.current) {
        p5InstanceRef.current.resizeCanvas(window.innerWidth, window.innerHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const nextEvent = () => {
    setCurrentEventIndex((prevIndex) =>
      prevIndex === eventsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevEvent = () => {
    setCurrentEventIndex((prevIndex) =>
      prevIndex === 0 ? eventsData.length - 1 : prevIndex - 1
    );
  };


  // Auto slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextEvent();
    }, 5000);
    return () => clearInterval(interval);
  }, []);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state
  };

  // Auto-play functionality for slideshow
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  // Fetch events from Firebase
  // useEffect(() => {
  //   const fetchEvents = async () => {
  //     try {
  //       const eventsCollection = collection(db, 'events');
  //       const eventsQuery = query(eventsCollection, orderBy('EventDate', 'desc'), limit(10));
  //       const snapshot = await getDocs(eventsQuery);

  //       const eventsList = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //         EventDate: new Date(doc.data().EventDate),
  //       }));

  //       setEvents(eventsList);
  //       setEventAppear(new Array(eventsList.length).fill(false)); // Initialize eventAppear state
  //     } catch (error) {
  //       console.error('Error fetching events:', error);
  //     }
  //   };

  //   fetchEvents();
  // }, []);

  // const filteredEvents = events.filter((event) => {
  //   const lowerCaseSearchTerm = searchTerm.toLowerCase();
  //   return (
  //     event.postDescription.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     event.location?.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     (event.EventDate && event.EventDate.toLocaleDateString().includes(lowerCaseSearchTerm))
  //   );
  // });

  // Trigger animation after component mounts
  // useEffect(() => {
  //   if (events.length > 0) {
  //     const timer = setTimeout(() => {
  //       setEventAppear(events.map((_, i) => true)); // Set all events to appear
  //     }, 500); // Delay to simulate falling animation

  //     return () => clearTimeout(timer);
  //   }
  // }, [events]);

  const handleImageError = (e) => {
    e.target.src = ''; // Remove broken image
    e.target.classList.add('image-error'); // Add class for gray background
  };


  const handleSlideChange = (direction) => {
    if (direction === -1) {
      // Go to the previous slide
      setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
    } else {
      // Go to the next slide
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }
  };

  
  return (
    <div className="home-container">
    
    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}



 
{/* Fullscreen Overlay Menu */}
<div className={`overlay ${isMenuOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleMenu}>&times;</button>
        <div className="menu-grid">


          <Link to="/login" className="menu-item">
          <img src={gravelImage} alt="Login" />
            <p>Join</p>
          </Link>
          {/* Add more links here */}
        </div>
      </div>

         {/*<div className="hero-network">
      <Sketch setup={setup} draw={draw} />
    </div> 
    */}


<div className="hero-network">
        <Sketch setup={setup} draw={draw} windowResized={windowResized} />
        <div className="hero-content">
          <h1>Welcome to Boon</h1>
          <p >Expand your network, elevate your connections</p>
          <Link to="/login" className="cta-button">Get Started</Link>
        </div>
      </div>



    {/* <div className="hero-section">
  <div className="hero-content">
    <h1>Welcome to Boon</h1>
    <p>Discover Events, Connect with People, and Enjoy Your Community</p>
    <a href="/login" className="cta-button">Get Started</a>
  </div>
</div> */}





     {/* Slideshow */}

     {/* <div className="slideshow-container">
  <div className="slideshow">
    <img src={images[currentSlide]} alt={`slide-${currentSlide}`} className="slideshow-image" />
    <div className="slideshow-caption">Caption for Slide {currentSlide + 1}</div>
  </div>
  
  <div>
  <button className="prev" onClick={() => handleSlideChange(-1)}>❮</button>
  <button className="next" onClick={() => handleSlideChange(1)}>❯</button>
  </div>
</div> */}


<div className="features-section">
  <h2>Why Choose Boon?</h2>
  <div className="features-grid">
    <div className="feature">
      <h3>Industry Networking</h3>
      <p>Connect with professionals in your industry, explore new collaborations, and unlock career opportunities.</p>
      <a href="/networking" className="cta-button">Learn More</a>
    </div>
    <div className="feature">
      <h3>Build Your Brand</h3>
      <p>Showcase your expertise, grow your niche, and strengthen your professional presence within your industry.</p>
      <a href="/build-brand" className="cta-button">Learn More</a>
    </div>
    <div className="feature">
      <h3>Meet Industry Peers</h3>
      <p>Expand your network by connecting with like-minded individuals and forming meaningful relationships with peers in your field.</p>
      <a href="/meet-people" className="cta-button">Learn More</a>
    </div>
    <div className="feature">
      <h3>Grow Your Community</h3>
      <p>Develop and nurture your professional and personal community by engaging with others who share your passions and goals.</p>
      <a href="/community" className="cta-button">Learn More</a>
    </div>
  </div>
</div>




<div className="testimonial-section">
  <h2>What People Are Saying</h2>
  
  <div className="testimonial-card">
    <p>"Boon has been instrumental in helping me grow my professional network and attend events tailored to my industry!" - Miriam Cohen</p>
  </div>
  
  <div className="testimonial-card">
    <p>"Through Boon, I've connected with amazing people in my niche and built lasting relationships that have helped me advance my career." - Wei Zhang</p>
  </div>
  
  <div className="testimonial-card">
    <p>"Boon made it easy for me to find events that aligned with my interests and allowed me to expand my community in meaningful ways." - Olga Ivanova</p>
  </div>
  
  <div className="testimonial-card">
    <p>"Boon has opened doors to events and connections that have been incredibly valuable to my personal and professional growth." - Javier Martínez</p>
  </div>
  
</div>


<div className="bridging-gaps-section">
  <h2 className="bridging-gaps-title">Bridging Gaps Between Creators, Followers, and Executives</h2>
  <div className="bridging-gaps-grid">
    <div className="gap-feature">
      <h3>Creators and Followers</h3>
      <p>
        Boon enables creators to network directly with their followers,
        transforming passive followers into engaged communities. Creators
        can organize virtual meetups, share insights, and even collaborate
        on projects.
      </p>
    </div>
    <div className="gap-feature">
      <h3>Executives and Entry-Level Professionals</h3>
      <p>
        Through mentorship programs and networking events, Boon helps
        entry-level professionals build relationships with executives who
        can guide them, offer advice, and potentially open doors to
        career-changing opportunities.
      </p>
    </div>
  </div>

  <a href="/creator" className="cta-button">Learn More</a>

</div>




<div className="networking-section">
  <h2 className="networking-title">Empower Your Career with Professional Networking</h2>
  <div className="networking-grid">
    <div className="networking-feature">
      <h3>Access to Hidden Job Opportunities</h3>
      <p>
        Many jobs are not advertised publicly. Networking helps you find
        hidden opportunities, and personal referrals boost your chances
        of success.
      </p>
      <p className="networking-example">Example: John, a software developer, secured a role through a contact he met at a Boon event.</p>
    </div>
    <div className="networking-feature">
      <h3>Expanding Knowledge & Expertise</h3>
      <p>
        Learn from other professionals and gain insights that will
        help you in your career growth and personal development.
      </p>
      <p className="networking-example">Example: Emily raised funding for her startup after connecting with successful entrepreneurs through Boon.</p>
    </div>
    <div className="networking-feature">
      <h3>Build Relationships with Industry Leaders</h3>
      <p>
        Networking helps you connect with mentors, who can offer
        guidance and recommendations for your career.
      </p>
      <p className="networking-example">Example: Alex was offered a design job after engaging with a creative director at a Boon event.</p>
    </div>
  </div>
  <a href="/networking" className="cta-button">Learn More</a>

</div>





      <div className="sub-home-container">
        <h1 className="heading">Featured Events</h1>
      {/* Slideshow Section */}
      <div className="slideshow-container">
        <div
            // className="event-slide"
            style={{
    
            // height: '300px',
            position: 'relative',
            color: '#fff',
            }}
        >
            {/* Add overlay */}
         {/* Display the image as an element */}
        <img 
        src={eventsData[currentEventIndex].backgroundImage} 
        alt="Event" 
        style={{
            width: '100%',
            height:'100%',
            objectFit: 'cover',
        }} 
    />
            {/* Content */}
        </div>
        </div>
      </div>



      <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-container">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3 onClick={() => toggleFAQ(index)} className="faq-question">
              {faq.question}
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </h3>
            <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>


    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>





    </div>

    
  );
}

export default Home;
