import React, { useEffect, useState } from 'react';
import '../styles/FAQ.css'; // Assuming you’ll add custom styling
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user



function FAQ() {
  const [searchTerm, setSearchTerm] = useState('');
  const auth = getAuth();
  const currentUser = auth.currentUser;


  useEffect(() => {
    document.title = 'FAQ'; // Set the new title
  }, []); // Runs only once when the component mounts

  // Sample FAQ Data
  const faqs = [
    // General questions
    { question: 'How do I reset my password?', answer: 'To reset your password, go to the settings page and click on "Reset Password". You will receive an email with instructions to reset it.' },
    { question: 'How do I change my email address?', answer: 'You can change your email address by going to your profile settings. Make sure to verify your new email once it is updated.' },
    { question: 'What is Boon?', answer: 'Boon is a platform designed to help users build communities, connect for personal and professional growth, and discover events that match their interests.' },
    { question: 'How do I delete my account?', answer: 'You can delete your account by navigating to the "Delete Account" section in settings. Once deleted, your account cannot be restored.' },
    { question: 'Is Boon free to use?', answer: 'Yes, Boon offers free access to essential features like event discovery and creation. We also offer premium plans with additional features.' },
    { question: 'Can I upgrade to a premium plan?', answer: 'Yes, you can upgrade to a premium plan by visiting the billing section in your account settings. Premium plans offer more advanced features.' },
  
    // Events and community
    { question: 'How do I create an event?', answer: 'To create an event, sign in to your Boon account, click on "Create Event", and fill in the event details. You can also choose to make it private or public.' },
    { question: 'How do I join an event?', answer: 'You can browse available events by location, category, or date. Simply click on the event you’re interested in and follow the registration instructions.' },
    { question: 'Can I invite friends to an event?', answer: 'Yes, once you create or join an event, you can invite friends by sharing the event link or sending them an invitation via email through the Boon platform.' },
    { question: 'How do I find events near me?', answer: 'You can filter events based on location by enabling location services or manually entering your location in the search filters.' },
    { question: 'Can I create a private event?', answer: 'Yes, when creating an event, you can set it to "Private" to limit visibility. Only users with an invite or a direct link can access the event.' },
    { question: 'Can I sell tickets for my event?', answer: 'Yes, Boon allows you to set ticket prices for your event. You can manage ticket sales, check-in attendees, and track sales through your event dashboard.' },
  
    // Account and privacy
    { question: 'How do I update my profile information?', answer: 'You can update your profile information by visiting your profile settings. From there, you can edit your name, bio, profile picture, and contact details.' },
    { question: 'What should I do if my account is hacked?', answer: 'If you suspect unauthorized activity on your account, reset your password immediately and contact our support team for further assistance.' },
    { question: 'Can I hide my profile from public view?', answer: 'Yes, you can adjust your privacy settings to control what information is visible to other users. You can choose to hide your profile from non-contacts.' },
    { question: 'How do I manage my notification settings?', answer: 'You can manage your email and in-app notifications by going to your settings and selecting "Notifications". Here, you can customize which alerts you want to receive.' },
    { question: 'What happens if I block another user?', answer: 'When you block a user, they will no longer be able to view your profile, send you messages, or interact with your posts or events.' },
    { question: 'Can I report inappropriate behavior?', answer: 'Yes, you can report inappropriate content or behavior by clicking the "Report" button on a user’s profile or on the content in question. Our team will review the report.' },
  
    // Payment and billing
    { question: 'What payment methods are accepted for premium plans?', answer: 'We accept major credit cards and PayPal for premium plan payments. You can manage your payment methods in your account settings.' },
    { question: 'Can I cancel my premium subscription?', answer: 'Yes, you can cancel your premium subscription at any time by visiting your billing settings. Once canceled, your plan will remain active until the end of the billing cycle.' },
    { question: 'What happens if my payment fails?', answer: 'If your payment fails, you will be notified via email. You can update your payment details in the billing section. Failure to update may result in a downgrade to the free plan.' },
    { question: 'How do I update my billing information?', answer: 'You can update your billing information by visiting your billing settings and adding new payment details or modifying your existing payment method.' },
    { question: 'Are there discounts for non-profits or students?', answer: 'Yes, Boon offers special pricing for non-profits and students. You can apply for a discount by contacting our support team with the required verification documents.' },
    { question: 'Can I get a refund for my premium subscription?', answer: 'Refunds for premium subscriptions are considered on a case-by-case basis. Please contact our support team to discuss your situation if you feel you’re entitled to a refund.' },
  
    // Technical support
    { question: 'How do I report a bug?', answer: 'If you encounter a bug or technical issue, please report it to our support team through the "Report a Bug" feature in the app or contact us via email.' },
    { question: 'Why am I having trouble accessing the site?', answer: 'If you’re experiencing difficulty accessing the site, check your internet connection and ensure you are using an up-to-date browser. If the issue persists, contact support.' },
    { question: 'How do I clear my browser’s cache?', answer: 'To clear your cache, go to your browser’s settings, find the "Clear Browsing Data" section, and select "Cache". This may resolve issues with loading the site.' },
    { question: 'What should I do if my account is locked?', answer: 'If your account is locked, it may be due to multiple failed login attempts. Try resetting your password, or contact support for assistance unlocking your account.' },
    { question: 'How do I enable two-factor authentication?', answer: 'To enable two-factor authentication, go to your security settings and follow the instructions to link your phone number or authentication app.' },
    { question: 'Why am I not receiving email notifications?', answer: 'Check your spam or junk folder for Boon emails. If you still aren’t receiving notifications, ensure that your email is correct in your profile settings and that notifications are enabled.' },
  ];

  // Filter FAQs based on the search term
  const filteredFAQs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // If there's no search term, show only the top FAQ (first one)
  const faqsToDisplay = searchTerm ? filteredFAQs : [faqs[0]];

  return (
    <div>
                      
    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}


    <div className="faq-container">



      <h1>Frequently Asked Questions</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search FAQs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="faq-list">
        {faqsToDisplay.length > 0 ? (
          faqsToDisplay.map((faq, index) => (
            <div key={index} className="faq-item">
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))
        ) : (
          <p>No FAQs found matching your search criteria.</p>
        )}
      </div>
    </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>

    </div>

  );
}

export default FAQ;
