import React, { useEffect, useState } from 'react';
import '../styles/Networking.css'; // Reusing the same styles
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

const Community = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  useEffect(() => {
    document.title = 'Community'; // Set the new title
  }, []); // Runs only once when the component mounts


  return (
    <div className="networking-container">

      

    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}


      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Join a Thriving Community</h1>
          <p>Connect, Collaborate, and Grow Together</p>
          <a href="#get-started" className="cta-button">Join a Community Now</a>
        </div>
      </section>

      {/* Why Join Section */}
      <section className="benefits-section">
        <h2 className="section-title">Why Join a Community?</h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <h3>Share Knowledge and Resources</h3>
            <p>Engage in meaningful discussions and share valuable resources with like-minded individuals.</p>
          </div>
          <div className="benefit-item">
            <h3>Collaborate on Projects</h3>
            <p>Collaborate with other professionals on projects that can help you grow your skill set and portfolio.</p>
          </div>
          <div className="benefit-item">
            <h3>Build Long-Lasting Connections</h3>
            <p>Develop lasting relationships that can support you personally and professionally.</p>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="success-stories-section">
        <h2 className="section-title">Community Success Stories</h2>
        <div className="success-story">
          <p>"Joining this community allowed me to find my co-founder and launch our startup."</p>
          <p>- James, Startup Founder</p>
        </div>
        <div className="success-story">
          <p>"Through collaboration with others, I was able to improve my skills and land a job at a tech company."</p>
          <p>- Mia, Software Developer</p>
        </div>
      </section>

      {/* How to Join Section */}
      <section className="how-it-works-section">
        <h2 className="section-title">How to Join and Grow Your Community</h2>
        <div className="how-it-works-grid">
          <div className="how-it-works-item">
            <h3>Find the Right Group</h3>
            <p>Explore different communities and choose the one that aligns with your interests and goals.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Participate in Events</h3>
            <p>Join virtual events, discussions, and meetups to engage with other members.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Start Your Own Community</h3>
            <p>Can't find the right group? Create your own and invite others to join.</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section id="get-started" className="cta-section">
        <h2>Ready to Join or Start Your Own Community?</h2>
        <a href="/signup" className="cta-button">Join Boon Today</a>
      </section>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
};

export default Community;
