import React, { useEffect, useState } from 'react';
import '../styles/AnalyticsDashboard.css'; // Custom CSS for styling
import { Link } from 'react-router-dom';

function AnalyticsDashboard() {
  const [analyticsData, setAnalyticsData] = useState({});
  useEffect(() => {
    document.title = 'Analytics'; // Set the new title
  }, []); // Runs only once when the component mounts
  useEffect(() => {
    // Fetch analytics data from API or database
    const fetchData = async () => {
      // Simulate fetching data
      const data = {
        totalAttendees: 500,
        totalRevenue: 10000,
        eventEngagement: 85,
      };
      setAnalyticsData(data);
    };
    
    fetchData();
  }, []);

  return (
    <div className="analytics-dashboard">
        <header className="header">
    <div className="header-left">
      <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
        <h1 className="company-name">Boon</h1>
      </Link>
    </div>
    <div className="header-right">
      <Link to="/login" className="nav-link">Login</Link>
    </div>
  </header>

      <h1>Analytics and Reports Dashboard</h1>
      <div className="metrics">
        <div className="metric-card">
          <h2>Total Attendees</h2>
          <p>{analyticsData.totalAttendees}</p>
        </div>
        <div className="metric-card">
          <h2>Total Revenue</h2>
          <p>${analyticsData.totalRevenue}</p>
        </div>
        <div className="metric-card">
          <h2>Event Engagement</h2>
          <p>{analyticsData.eventEngagement}%</p>
        </div>
      </div>




    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default AnalyticsDashboard;
