import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import '../styles/Home.css';

function Home() {
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true }); // Transparent background
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Add light for better visibility
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    // Create a plane geometry
    const geometry = new THREE.PlaneGeometry(20, 20, 64, 64); // Width, Height, Width segments, Height segments

    // ShaderMaterial for the trippy effect
    const shaderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        u_time: { value: 0.0 },
        u_color1: { value: new THREE.Color(0xff0066) },
        u_color2: { value: new THREE.Color(0x00ff99) },
      },
      vertexShader: `
        uniform float u_time;
        varying vec2 vUv;
        varying float vNoise;
        
        // GLSL Simplex Noise
        vec3 mod289(vec3 x) {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        vec2 mod289(vec2 x) {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        vec3 permute(vec3 x) {
          return mod289(((x*34.0)+1.0)*x);
        }
        float snoise(vec2 v) {
          const vec4 C = vec4(0.211324865405187, 0.366025403784439,
                              -0.577350269189626, 0.024390243902439);
          vec2 i  = floor(v + dot(v, C.yy) );
          vec2 x0 = v -   i + dot(i, C.xx);
          vec2 i1;
          i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
          vec4 x12 = x0.xyxy + C.xxzz;
          x12.xy -= i1;
          i = mod289(i); 
          vec3 p = permute(permute( i.y + vec3(0.0, i1.y, 1.0))
                + i.x + vec3(0.0, i1.x, 1.0));
          vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
          m = m*m ;
          m = m*m ;
          vec3 x = 2.0 * fract(p * C.www) - 1.0;
          return 130.0 * dot( m*m, x );
        }

        void main() {
          vUv = uv;

          // Create a wave effect by modifying the vertices with time and noise
          vec3 pos = position;
          float noise = snoise(pos.xy * 0.5 + u_time * 0.1) * 2.0;
          vNoise = noise;
          pos.z += sin(pos.x * 5.0 + u_time * 0.5) * 0.5 * noise;
          pos.y += sin(pos.y * 5.0 + u_time * 0.5) * 0.5 * noise;

          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 u_color1;
        uniform vec3 u_color2;
        varying vec2 vUv;
        varying float vNoise;

        void main() {
          // Interpolate between two colors using UV coordinates and noise
          vec3 color = mix(u_color1, u_color2, vUv.y + vNoise * 0.5);
          gl_FragColor = vec4(color, 1.0);
        }
      `,
    });

    // Create a mesh with the plane geometry and shader material
    const plane = new THREE.Mesh(geometry, shaderMaterial);
    plane.rotation.x = -Math.PI / 2; // Rotate the plane to be horizontal
    scene.add(plane);

    camera.position.z = 10;
    camera.position.y = 5;

    // Animation loop
    const animate = function () {
      requestAnimationFrame(animate);

      // Update the shader time uniform for animation
      shaderMaterial.uniforms.u_time.value += 0.05;

      // Rotate the camera slightly for a more trippy effect
      camera.position.x = Math.sin(Date.now() * 0.001) * 10;
      camera.lookAt(new THREE.Vector3(0, 0, 0));

      renderer.render(scene, camera);
    };

    animate();

    // Cleanup on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div className="home-container">
      <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>
        <div className="header-right">
          {/* <a href="/login" className="nav-link">Login</a> */}
        </div>
      </header>

      <div ref={mountRef} className="background-3d" />

      <div className="sub-home-container">
        <h1 className="heading">Trippy 3D Background</h1>
        <p>Enjoy the dynamic psychedelic background!</p>
      </div>
    </div>
  );
}

export default Home;
