import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginSignup.css';
import { db, auth } from '../firebase'; // Assuming Firebase is initialized
import { Link } from 'react-router-dom';
import { doc, setDoc, addDoc, collection, Timestamp, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // FontAwesome icons for eye

function LoginSignup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [error, setError] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [enteredCode, setEnteredCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // State to handle password visibility
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Login or Signup';
  }, []);

  const generateConfirmationCode = () => {
    return Math.floor(1000 + Math.random() * 9000).toString(); // Generate a random 4-digit code
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/homefeed');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;
      setUserId(userId);

      const code = generateConfirmationCode();

      await addDoc(collection(db, 'mail'), {
        to: email,
        message: {
          subject: 'Boon Email Confirmation',
          html: `<p>Your confirmation code is: <b>${code}</b></p>`,
          text: `Your confirmation code is: ${code}`,
        },
        attempts: 1,
        startTime: Timestamp.now(),
        state: 'PENDING',
      });

      await setDoc(doc(db, 'users', userId), {
        email: email,
        confirmationCode: code,
        verified: false,
      }, { merge: true });

      setCodeSent(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleConfirmation = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists() && userDoc.data().confirmationCode === enteredCode) {
        await setDoc(userDocRef, { verified: true }, { merge: true });
        navigate(`/userdetails/${userId}`);
      } else {
        setError('Invalid confirmation code. Please try again.');
      }
    } catch (error) {
      setError("Error verifying the user: " + error.message);
    }
  };

  return (
    <div>
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link">
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Login</Link>
        </div>
      </header>

      <div className="login-signup-container">
        <h1>{isSignup ? 'Sign Up' : 'Login'}</h1>
        <form onSubmit={isSignup ? handleSignup : handleLogin}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <span
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)} // Toggle visibility on click
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </span>
          </div>
          {error && <p className="error-message">{error}</p>}
          {!codeSent && <button type="submit">{isSignup ? 'Sign Up' : 'Login'}</button>}
        </form>

        {/* Show confirmation code input only after the code is sent */}
        {codeSent && (
          <form onSubmit={handleConfirmation}>
            <input
              type="text"
              value={enteredCode}
              onChange={(e) => setEnteredCode(e.target.value)}
              placeholder="Enter confirmation code"
              required
            />
            <button type="submit">Confirm Code</button>
          </form>
        )}

        <p>
          {isSignup ? 'Already have an account?' : "Don't have an account?"}{' '}
          <span onClick={() => setIsSignup(!isSignup)} className="toggle-link">
            {isSignup ? 'Login' : 'Sign Up'}
          </span>
        </p>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <h2>Boon</h2>
            <p>Empowering community building and professional growth.</p>
          </div>

          <div className="footer-links">
            <div className="footer-column">
              <h3>Boon</h3>
              <a href="/about">About</a>
              <a href="/careers">Careers</a>
            </div>
            <div className="footer-column">
              <h3>Resources</h3>
              <a href="/support">Support</a>
              <a href="/faq">FAQ</a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 Boon. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default LoginSignup;
