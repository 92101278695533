import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase'; // Assuming Firebase is initialized
import { doc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore'; // Import doc and getDoc
import '../styles/InviteToHangout.css'; // Custom CSS for this component
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user
function InviteToHangout() {
  const { userId } = useParams(); // Get the recipient's user ID from the route
  const [message, setMessage] = useState('');
  const [recipient, setRecipient] = useState(null);
  // const [currentUser, setCurrentUser] = useState(null); // Fetch from auth or state
  const auth = getAuth();
  const currentUser = auth.currentUser;

  

  const navigate = useNavigate();
  useEffect(() => {

    document.title = 'Hangout Invite'; // Set the new title
  }, []); // Runs only once when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the recipient's user details based on the userId
      const userDoc = await getDoc(doc(db, 'users', userId));
      setRecipient(userDoc.data());
    };

    fetchUser();
  }, [userId]);

  const handleSendInvite = async () => {
    if (!currentUser || !recipient) return;

    try {
      // Add the hangout invitation to the database
      await addDoc(collection(db, 'hangoutInvites'), {
        fromUser: currentUser.id,
        toUser: recipient.id,
        message,
        status: 'pending', // Initial status of the invitation
        timestamp: serverTimestamp(),
      });

      // Redirect back to connections or show success message
      navigate('/connections');
    } catch (error) {
      console.error('Error sending invite:', error);
    }
  };

  return (
    <div className="invite-container">

    <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>


      <h1>Invite {recipient?.name} to Hangout</h1>
      <textarea
        placeholder="Write a message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="message-input"
      />
      <button onClick={handleSendInvite} className="send-button">Send Invite</button>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>



    </div>
  );
}

export default InviteToHangout;
