import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Assuming Firebase is initialized
import '../styles/HangoutResponse.css'; // Custom CSS for this component
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function HangoutResponse() {
  const { inviteId } = useParams(); // Get the invite ID from the route
  const [invite, setInvite] = useState(null);
  const auth = getAuth();
  const currentUser = auth.currentUser;



  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Brand Building'; // Set the new title
  }, []); // Runs only once when the component mounts
  useEffect(() => {
    const fetchInvite = async () => {
      const inviteDoc = await getDoc(doc(db, 'hangoutInvites', inviteId));
      setInvite(inviteDoc.data());
    };

    fetchInvite();
  }, [inviteId]);

  const handleResponse = async (status) => {
    try {
      const inviteRef = doc(db, 'hangoutInvites', inviteId);
      await updateDoc(inviteRef, { status });

      // Redirect to another page (e.g., connections or dashboard)
      navigate('/connections');
    } catch (error) {
      console.error('Error updating invite status:', error);
    }
  };

  return (
    <div className="response-container">

   
{currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>Message</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Login</Link>
        </div>
      </header>
    )}


      <h1>Respond to Hangout Invite</h1>
      <p>Message from {invite?.fromUser}: {invite?.message}</p>
      <button onClick={() => handleResponse('accepted')} className="accept-button">Accept</button>
      <button onClick={() => handleResponse('ignored')} className="ignore-button">Ignore</button>
      <button onClick={() => handleResponse('denied')} className="deny-button">Deny</button>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default HangoutResponse;
