import React, { useEffect, useState } from 'react';import EditModal from './EditModal';
import '../styles/SettingsPage.css';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function SettingsPage() {
  const [isModalOpen, setIsModalOpen] = useState({});
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Function to open/close a specific modal
  const toggleModal = (setting) => {
    setIsModalOpen((prev) => ({ ...prev, [setting]: !prev[setting] }));
  };

  useEffect(() => {
    document.title = 'Settings'; // Set the new title
  }, []); // Runs only once when the component mounts

  return (
    <div className="settings-page">
          <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>


      <h1>Settings</h1>
      <div className="settings-list">
        {/* Example Settings */}
        <div className="setting-item">
          <p>Change Username</p>
          <button onClick={() => toggleModal('username')}>Edit</button>
        </div>
        <div className="setting-item">
          <p>Change Password</p>
          <button onClick={() => toggleModal('password')}>Edit</button>
        </div>
        <div className="setting-item">
          <p>Notification Preferences</p>
          <button onClick={() => toggleModal('notifications')}>Edit</button>
        </div>

        {/* Modal for Username */}
        {isModalOpen.username && (
          <EditModal
            title="Edit Username"
            onClose={() => toggleModal('username')}
          >
            <form>
              <input type="text" placeholder="New Username" />
              <button type="submit">Save</button>
            </form>
          </EditModal>
        )}

        {/* Modal for Password */}
        {isModalOpen.password && (
          <EditModal
            title="Edit Password"
            onClose={() => toggleModal('password')}
          >
            <form>
              <input type="password" placeholder="New Password" />
              <input type="password" placeholder="Confirm Password" />
              <button type="submit">Save</button>
            </form>
          </EditModal>
        )}

        {/* Modal for Notifications */}
        {isModalOpen.notifications && (
          <EditModal
            title="Edit Notification Preferences"
            onClose={() => toggleModal('notifications')}
          >
            <form>
              <label>
                <input type="checkbox" /> Email Notifications
              </label>
              <label>
                <input type="checkbox" /> SMS Notifications
              </label>
              <button type="submit">Save</button>
            </form>
          </EditModal>
        )}
      </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default SettingsPage;
