import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../firebase'; // Assuming Firebase is initialized
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import '../styles/TicketDetails.css'; // Assuming the styles file exists
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function TicketDetails() {
  const { ticketId } = useParams(); // Get the ticketId from the URL
  const [ticketDetails, setTicketDetails] = useState(null);
  const [eventDetails, setEventDetails] = useState(null); // To store event details
  const [isRedeemed, setIsRedeemed] = useState(false); // State to handle redemption
  const [showConfirmation, setShowConfirmation] = useState(false); // State for showing confirmation modal
  const auth = getAuth();
  const currentUser = auth.currentUser;


  useEffect(() => {
    document.title = 'Ticket Details'; // Set the new title
  }, []); // Runs only once when the component mounts


  useEffect(() => {
    // Fetch ticket details from Firestore based on the ticketId
    const fetchTicketAndEventDetails = async () => {
      if (!ticketId) return;

      try {
        const ticketDocRef = doc(db, 'tickets', ticketId);
        const ticketDocSnap = await getDoc(ticketDocRef);

        if (ticketDocSnap.exists()) {
          const ticketData = ticketDocSnap.data();
          setTicketDetails({
            id: ticketDocSnap.id,
            ...ticketData,
          });

          setIsRedeemed(ticketData.redeemed || false); // Check if the ticket is redeemed

          // Fetch the event details associated with the ticket
          const eventDocRef = doc(db, 'events', ticketData.eventId); // Assuming ticket contains the eventId
          const eventDocSnap = await getDoc(eventDocRef);

          if (eventDocSnap.exists()) {
            setEventDetails({
              id: eventDocSnap.id,
              EventDate: new Date(eventDocSnap.data().EventDate.seconds * 1000), // Convert Firestore timestamp to JS Date
              postDescription: eventDocSnap.data().postDescription,
              eventBio: eventDocSnap.data().eventBio,
              postPhoto: eventDocSnap.data().postPhoto,
              attendees: eventDocSnap.data().attendees?.length || 0, // Get the length of attendees array
              views: eventDocSnap.data().views?.length || 0, // Get the length of views array
              location: eventDocSnap.data().postLocation?.name || 'Unknown location',
              city: eventDocSnap.data().postLocation?.city || 'Unknown city',
            });
          }
        } else {
          console.error('Ticket not found');
        }
      } catch (error) {
        console.error('Error fetching ticket and event details:', error);
      }
    };

    fetchTicketAndEventDetails();
  }, [ticketId]);

  const handleRedeem = async () => {
    try {
      const ticketDocRef = doc(db, 'tickets', ticketId);

      // Update the ticket's "redeemed" status in Firestore
      await updateDoc(ticketDocRef, {
        redeemed: true,
      });

      // Reflect the change in the UI
      setIsRedeemed(true);
      setShowConfirmation(false); // Close the confirmation modal
    } catch (error) {
      console.error('Error redeeming ticket:', error);
    }
  };

  const openConfirmation = () => {
    setShowConfirmation(true); // Open the confirmation modal
  };

  const closeConfirmation = () => {
    setShowConfirmation(false); // Close the confirmation modal
  };

  if (!ticketDetails || !eventDetails) {
    return <div>Loading ticket details...</div>; // Show loading state while fetching data
  }

  return (
    <div>  
      
      <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    <div className="ticket-details-page">
    

      {/* Ticket Details UI */}
      <div className="ticket-details-container">
        <div className="ticket-details-card">
          <h2>Your Ticket</h2>
          <p><strong>Event Name:</strong> {ticketDetails.eventName}</p>
          <p><strong>Email:</strong> {ticketDetails.email}</p>
          <p><strong>Event Date:</strong> {eventDetails.EventDate ? eventDetails.EventDate.toLocaleDateString() : 'TBD'}</p>
          <p><strong>Location:</strong> {eventDetails.location}, {eventDetails.city}</p>
          <p><strong>Ticket ID:</strong> {ticketDetails.id}</p>
          <p><strong>Redeemed:</strong> {isRedeemed ? 'Yes' : 'No'}</p>

          {eventDetails.postPhoto && (
            <div className="event-photo">
              <img src={eventDetails.postPhoto} alt="Event" />
            </div>
          )}

          {/* Show "Mark as Redeemed" button only if the ticket is not redeemed */}
          {!isRedeemed && (
            <button className="redeem-button" onClick={openConfirmation}>
              Mark as Redeemed
            </button>
          )}

          <Link to={`/event/${ticketDetails.eventId}`} className="view-event-button">View Event Details</Link>
        </div>
      </div>

      {/* Confirmation Popup */}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h3>Are you sure you want to redeem this ticket?</h3>
            <div className="confirmation-buttons">
              <button className="confirmation-yes" onClick={handleRedeem}>Yes</button>
              <button className="confirmation-no" onClick={closeConfirmation}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>



    </div>
  );
}

export default TicketDetails;
