import React, { useState, useEffect } from 'react';
import '../styles/VenueManagement.css'; // Custom CSS for styling
import { Link } from 'react-router-dom';

import { getAuth } from 'firebase/auth'; // To get the authenticated user

function VenueManagement() {
  const [venues, setVenues] = useState([]);

  const auth = getAuth();
  const currentUser = auth.currentUser;



  useEffect(() => {
    document.title = 'Venue Management'; // Set the new title
  }, []); // Runs only once when the component mounts



  useEffect(() => {
    // Fetch venue data from API or database
    const fetchVenues = async () => {
      // Simulate fetching data
      const venueList = [
        { id: 1, name: 'Venue A', availability: 'Available', price: 200 },
        { id: 2, name: 'Venue B', availability: 'Booked', price: 300 },
      ];
      setVenues(venueList);
    };

    fetchVenues();
  }, []);

  return (
    <div className="venue-management">

        <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>


      <h1>Venue Management</h1>
      <div className="venue-list">
        {venues.map((venue) => (
          <div key={venue.id} className="venue-card">
            <h2>{venue.name}</h2>
            <p>Availability: {venue.availability}</p>
            <p>Price: ${venue.price}</p>
          </div>
        ))}
      </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default VenueManagement;
