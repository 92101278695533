import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Assuming Firebase is initialized correctly
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'; // Firestore methods
import '../styles/Support.css'; // Assuming you’ll add custom styling
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user


function Support() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');
  const [message, setMessage] = useState('');
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Support'; // Set the new title
  }, []); // Runs only once when the component mounts


  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !question) {
      setMessage('Please fill in all fields.');
      return;
    }

    try {
      // Save the user details and question to Firestore
      await addDoc(collection(db, 'support'), {
        name,
        email,
        question,
        timestamp: serverTimestamp(), // Add a server timestamp for record-keeping
      });

      // Clear the form and show success message
      setMessage('Your question has been submitted successfully! Our support team will reach out soon.');
      setName('');
      setEmail('');
      setQuestion('');
    } catch (error) {
      console.error('Error submitting question: ', error);
      setMessage('Error submitting your question. Please try again.');
    }
  };

  return (
    <div>
      
    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}

      <div className="support-container">
        <h1>Support</h1>
        <p>Have questions or need assistance? Fill out the form below, and our support team will get back to you as soon as possible.</p>

        <form className="support-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Enter your full name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email address"
            />
          </div>

          <div className="form-group">
            <label htmlFor="question">Your Question</label>
            <textarea
              id="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
              placeholder="Enter your question"
              rows="4"
            />
          </div>

          <button type="submit">Submit</button>
        </form>

        {message && <p className="message">{message}</p>}
      </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default Support;
