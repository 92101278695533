import React, { useEffect, useState } from 'react';
import '../styles/TicketingManagement.css'; // Custom CSS for styling
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function TicketingManagement() {
  const [tickets, setTickets] = useState([]);
  const auth = getAuth();
  const currentUser = auth.currentUser;


  useEffect(() => {
    document.title = 'Ticket Management'; // Set the new title
  }, []); // Runs only once when the component mounts




  useEffect(() => {
    // Fetch ticketing data from API or database
    const fetchTickets = async () => {
      // Simulate fetching data
      const ticketList = [
        { id: 1, type: 'VIP', price: 100, sold: 50, available: 20 },
        { id: 2, type: 'General Admission', price: 50, sold: 100, available: 50 },
      ];
      setTickets(ticketList);
    };

    fetchTickets();
  }, []);

  return (
    <div className="ticketing-management">

      <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>


      <h1>Ticketing Management</h1>
      <div className="ticket-list">
        {tickets.map((ticket) => (
          <div key={ticket.id} className="ticket-card">
            <h2>{ticket.type}</h2>
            <p>Price: ${ticket.price}</p>
            <p>Sold: {ticket.sold}</p>
            <p>Available: {ticket.available}</p>
          </div>
        ))}
      </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default TicketingManagement;
