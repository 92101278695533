import React, { useEffect, useState } from 'react';
import '../styles/Networking.css'; // Reusing the same styles for consistency
import { Link } from 'react-router-dom';

const BrandBuilding = () => {
  useEffect(() => {
    document.title = 'Brand Building'; // Set the new title
  }, []); // Runs only once when the component mounts
  return (
    <div className="networking-container"> {/* Reusing the general container style */}
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link">
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Login</Link>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Build Your Personal Brand</h1>
          <p>Elevate Your Presence, Amplify Your Impact</p>
          <a href="#get-started" className="cta-button">Start Building Your Brand</a>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="benefits-section"> {/* Reusing benefits-section */}
        <h2 className="section-title">Key Benefits of a Strong Brand</h2>
        <div className="benefits-grid"> {/* Reusing the grid layout */}
          <div className="benefit-item">
            <h3>Increased Visibility</h3>
            <p>A strong personal brand helps you stand out in a crowded market, attracting more opportunities.</p>
            <p className="example">Example: Sarah's consistent branding led to speaking engagements at industry conferences.</p>
          </div>
          <div className="benefit-item">
            <h3>Enhanced Credibility</h3>
            <p>Establish yourself as an expert in your field, building trust with your audience and peers.</p>
            <p className="example">Example: Tom's thought leadership articles resulted in collaborations with industry leaders.</p>
          </div>
          <div className="benefit-item">
            <h3>Career Advancement</h3>
            <p>A well-crafted personal brand can open doors to new career opportunities and higher-level positions.</p>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="success-stories-section"> {/* Reusing success-stories-section */}
      <h2 className="section-title">Brand Building Success Stories</h2>
      
      <div className="success-story">
        <p>"Boon's branding tools helped me create a consistent online presence, leading to a 50% increase in client inquiries!"</p>
        <p>- Leah, Freelance Designer</p>
      </div>
      
      <div className="success-story">
        <p>"Thanks to Boon's guidance, I've become a recognized thought leader in my industry, opening up numerous speaking opportunities."</p>
        <p>- Daniel, Tech Entrepreneur</p>
      </div>
      
      <div className="success-story">
        <p>"Boon provided me with the right resources to position myself as an authority in my field, which led to key partnerships."</p>
        <p>- Ming, Marketing Consultant</p>
      </div>
      
      <div className="success-story">
        <p>"With Boon's platform, I've been able to grow my business significantly by reaching new clients internationally."</p>
        <p>- Natalia, Business Owner</p>
      </div>
      
    </section>


      {/* How It Works Section */}
      <section className="how-it-works-section"> {/* Reusing how-it-works-section */}
        <h2 className="section-title">How to Build Your Brand on Boon</h2>
        <div className="how-it-works-grid"> {/* Reusing grid layout */}
          <div className="how-it-works-item">
            <h3>Content Creation</h3>
            <p>Utilize our platform to create and share valuable content that showcases your expertise.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Networking Opportunities</h3>
            <p>Connect with industry leaders and peers to expand your influence and learn from others.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Personal Branding Workshops</h3>
            <p>Attend our exclusive workshops to refine your brand strategy and learn from branding experts.</p>
          </div>
        </div>
      </section>

      {/* Brand Elements Section */}
      <section className="how-it-works-section"> {/* Reusing how-it-works-section */}
        <h2 className="section-title">Essential Elements of a Strong Personal Brand</h2>
        <div className="how-it-works-grid"> {/* Reusing grid layout */}
          <div className="how-it-works-item">
            <h3>Authenticity</h3>
            <p>Build a brand that genuinely reflects your values, personality, and unique strengths.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Consistency</h3>
            <p>Maintain a consistent message and visual identity across all platforms and interactions.</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section id="get-started" className="cta-section"> {/* Reusing cta-section */}
        <h2>Ready to Build Your Personal Brand?</h2>
        <a href="/signup" className="cta-button">Join Boon Today</a>
      </section>


    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
};

export default BrandBuilding;
