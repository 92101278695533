// Import the required functions from the SDKs
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyAAIb8ykqJl43s4_pyomTnqc9hy__oGD3g",
    authDomai: "auth-66c00.firebaseapp.com",
    databaseURL: "https://auth-66c00.firebaseio.com",
    projectId: "auth-66c00",
    storageBucket: "auth-66c00.appspot.com",
    messagingSenderId: "572400544442",
    appId: "1:572400544442:web:e68b98e296d9a395983083",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore (Database)
const db = getFirestore(app);

// Initialize Firebase Auth (for authentication)
const auth = getAuth(app);

// Initialize Firebase Storage
const storage = getStorage(app); // Export Firebase Storage

// Export db and auth so you can use them in your components
export { db, auth, storage };