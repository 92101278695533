import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase'; // Assuming Firebase is initialized
import { doc, getDoc, collection, getDocs, addDoc, query, where, serverTimestamp,updateDoc,arrayUnion } from 'firebase/firestore';
import '../styles/EventDetails.css'; // Assuming the styles file exists
import { Link, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth

function EventDetails() {
  const { eventId } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  // const [currentUser, setCurrentUser] = useState(null); // Assuming you will fetch the current logged-in user from auth or state
  const [showPopup, setShowPopup] = useState(false); // For the popup notification
  const [unauthenticatedEmail, setUnauthenticatedEmail] = useState(''); // For unauthenticated users
  const [showRSVPPopup, setShowRSVPPopup] = useState(false); // For the popup notification
  const [ticketId, setTicketId] = useState(null); // Store the ticketId after RSVP
  const auth = getAuth();
  const currentUser = auth.currentUser;


  useEffect(() => {
    document.title = eventDetails?.postDescription? eventDetails.postDescription : 'Event Details'; // Set the new title
  }, []); // Runs only once when the component mounts


 


  useEffect(() => {
    // Fetch the event details from Firestore
    const fetchEventDetails = async () => {
      if (!eventId) return;

      try {
        const eventDocRef = doc(db, 'events', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
          setEventDetails({
            id: eventDocSnap.id,
            EventDate: new Date(eventDocSnap.data().EventDate),
            postDescription: eventDocSnap.data().postDescription,
            eventBio: eventDocSnap.data().eventBio,
            postPhoto: eventDocSnap.data().postPhoto,
            attendees: eventDocSnap.data().attendees?.length || 0, // Get the length of attendees array or 0
            views: eventDocSnap.data().views?.length || 0, // Get the length of views array or 0
            location: eventDocSnap.data().postLocation?.name || 'Unknown location',
            city: eventDocSnap.data().postLocation?.city || 'Unknown city',



          });
          // Add user ID or placeholder ID to the views array
          const userId = currentUser?.uid || 'guest_' + Math.random().toString(36).substring(2, 15);
          await updateDoc(eventDocRef, {
            views: arrayUnion(userId),
          });
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  // Live search functionality
  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '>=', searchTerm), where('username', '<=', searchTerm + '\uf8ff'));
      const querySnapshot = await getDocs(q);
      setUsers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    if (searchTerm.length > 0) {
      fetchUsers();
    }
  }, [searchTerm]);

  const handleSendInvite = async () => {
    // if (!selectedUser) return;


      await addDoc(collection(db, 'directMessages'), {
        fromUser: currentUser?.uid ? currentUser.id : null,
        toUser: selectedUser?.uid ? selectedUser.uid : null,
        message: `You are invited to attend the event: ${eventDetails?.postDescription}`,
        eventId: eventId,
        timestamp: serverTimestamp(),
      });
      
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000); // Show popup for 3 seconds
      setSelectedUser(null); // Clear the selected user after sending
 
  };
  const handleRSVP = async () => {
    let ticketId = null;
    const eventDocRef = doc(db, 'events', eventId);
    const userId = currentUser?.uid || 'guest_' + Math.random().toString(36).substring(2, 15);
  
    // Check if the email (unauthenticatedEmail) or current user's email already has an RSVP for this event
    try {
      const ticketsRef = collection(db, 'tickets');
      const emailToCheck = currentUser?.email || unauthenticatedEmail;
      const q = query(ticketsRef, where('email', '==', emailToCheck), where('eventId', '==', eventId));
      const existingTicketsSnapshot = await getDocs(q);
  
      if (!existingTicketsSnapshot.empty) {
        // If a ticket already exists, extract the ticket ID and show a popup with the link
        const existingTicket = existingTicketsSnapshot.docs[0]; // Assuming there’s only one ticket
        ticketId = existingTicket.id;
        setTicketId(ticketId);
        setShowRSVPPopup(true);
        setTimeout(() => setShowRSVPPopup(false), 10000); // Show popup for 10 seconds
        return; // Stop further execution, since the user is already RSVP'd
      }
  
      // Add the user ID or placeholder ID to the attendees array
      await updateDoc(eventDocRef, {
        attendees: arrayUnion(userId),
      });
  
      if (currentUser && currentUser.uid) {
        // Authenticated user RSVP
        const ticketDocRef = await addDoc(collection(db, 'tickets'), {
          userId: currentUser.uid,
          email: currentUser.email,
          eventId: eventId,
          eventName: eventDetails.postDescription,
          timestamp: serverTimestamp(),
        });
        ticketId = ticketDocRef.id;
        setTicketId(ticketDocRef.id); // Store the ticketId after successful RSVP
  
        setShowRSVPPopup(true);
        setTimeout(() => setShowRSVPPopup(false), 10000); // Show popup for 10 seconds
      } else if (unauthenticatedEmail) {
        // Unauthenticated user RSVP using email
        const ticketDocRef = await addDoc(collection(db, 'tickets'), {
          email: unauthenticatedEmail,
          eventId: eventId,
          eventName: eventDetails.postDescription,
          timestamp: serverTimestamp(),
        });
  
        // Get the ticketId from the newly created document
        ticketId = ticketDocRef.id;
  
        // Add entry to 'mail' collection with the ticketId and email
        await addDoc(collection(db, 'mail'), {
          code: 261709,
          date: new Date(),
          delivery: {},
          attempts: 1,
          endTime: new Date(),
          error: null,
          info: {},
          accepted: [unauthenticatedEmail],
          messageId: "<d6d75621-d7b3-8e94-caa7-606c13d8f785@tech-center.com>",
          pending: [],
          rejected: [],
          response: "250 Requested mail action okay, completed: id=0MUY45-1rH08Q12Dz-00RF7G",
          state: "SUCCESS",
          message: {
            html: `<center><h2>Can't wait to see you at the upcoming event!</h2><br><br>
                   Hello,\n\nYou have successfully RSVP'd for the event "${eventDetails?.postDescription}". Your ticket ID is: ${ticketId}.<br><br>
                  <a href="https://theboon.app/tickets/${ticketId}">View Your Ticket</a><br><br>
                  Stay in touch:<br>www.theboon.app<br></center>`,
            subject: "Boon: RSVP confirmation",
            text: `Hello,\n\nYou have successfully RSVP'd for the event "${eventDetails?.postDescription}". Your ticket ID is: ${ticketId}.`,
          },
          to: unauthenticatedEmail,
          startTime: new Date(),
        });
        
        ticketId = ticketDocRef.id;
        setTicketId(ticketDocRef.id); // Store the ticketId after successful RSVP
  
        setShowRSVPPopup(true);
        setTimeout(() => setShowRSVPPopup(false), 10000); // Show popup for 10 seconds
        setUnauthenticatedEmail(''); // Clear the email input after RSVP
      }
    } catch (error) {
      console.error('Error creating ticket or sending mail:', error);
    }
  };

  return (
    <div className="event-details-page">

    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Login</Link>
        </div>
      </header>
    )}


      {/* Event Banner */}
      {eventDetails && eventDetails.postPhoto && (
        <div className="event-banner" style={{ backgroundImage: `url(${eventDetails.postPhoto})` }}>
          <div className="banner-overlay">
            <h1 className="banner-title">{eventDetails?.postDescription}</h1>
          </div>
        </div>
      )}

            {/* Event Details UI */}
            <div className="event-details-container">
        <div className="event-details-card">
          <h2>{eventDetails?.postDescription}</h2>
          <p>{eventDetails?.eventBio}</p>
          <p><strong>Date:</strong> {eventDetails?.EventDate?.toLocaleDateString()}</p>
          <p><strong>Location:</strong> {eventDetails?.location}, {eventDetails?.city}</p>
          <p><strong>Attendees:</strong> {eventDetails?.attendees}</p>
          <p><strong>Views:</strong> {eventDetails?.views}</p>
          {!currentUser ? (
            <>
              <input
                type="email"
                placeholder="Enter your email to RSVP"
                value={unauthenticatedEmail}
                onChange={(e) => setUnauthenticatedEmail(e.target.value)}
                className="search-input"
                />
              <button className="purchase-button" onClick={handleRSVP}>RSVP with Email</button>
            </>
          ) : (
            <button className="purchase-button" onClick={handleRSVP}>RSVP</button>
          )}
        </div>
      </div>

        {/* Popup Notification */}
        {showRSVPPopup && ticketId && (
    <div className="popup">
          {`RSVP successful! `}
          {unauthenticatedEmail ? 'Check your email.' : 'You have already RSVP’d!'}
          <br />
          <Link to={`/tickets/${ticketId}`} className="ticket-link">
            View Your Ticket
          </Link>
        </div>
    )}


      {/* Invite a User */}
      <div className="invite-section">
        <h3>Invite a Friend</h3>
        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />

        <div className="search-results">
          {users.map(user => (
            <div
              key={user.id}
              className={`user-result ${selectedUser?.id === user.id ? 'selected' : ''}`}
              onClick={() => setSelectedUser(user)}
            >
              <img src={user.photo || '/default-avatar.png'} alt={user.username} className="user-avatar" />
              {user.username}
              {selectedUser?.id === user.id && <span className="blue-dot"></span>}
            </div>
          ))}
        </div>

        {selectedUser && (
          <div>
            <button onClick={handleSendInvite} className="invite-button">Send Invite</button>
          </div>
        )}
      </div>

      {/* Popup Notification */}
      {showPopup && <div className="popup">User invited successfully!</div>}



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>



    </div>
  );
}

export default EventDetails;
