import React, { useEffect, useState } from 'react';
import '../styles/TermsOfService.css'; // Assuming you will add custom styling
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function TermsOfService() {

  useEffect(() => {
    document.title = 'Terms of Service'; // Set the new title
  }, []); // Runs only once when the component mounts
  const auth = getAuth();
  const currentUser = auth.currentUser;



  return (
    <div className="terms-container">
        
        

    {currentUser.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}


        <h1>Terms of Service</h1>
        <p>Last Updated: September 16th, 2024</p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            These Terms of Service (the "Terms") govern your access to and use of the website located at [website] 
            (the "Platform"), as well as any and all related services, products, features, functionalities, and other 
            interactions provided by Boon (collectively referred to as the "Services"). By accessing or using the Platform 
            in any manner, whether you are a registered user or a visitor, you expressly acknowledge that you have read, 
            understood, and agree to be bound by these Terms, in their entirety, without exception. If you do not agree 
            with any part of these Terms, you must immediately cease all use of the Platform and its associated Services.
          </p>
          <p>
            Boon reserves the right, at its sole discretion, to modify, alter, amend, or update these Terms at any time, 
            with or without notice to you. Your continued access to or use of the Platform following the posting of any 
            modifications to these Terms constitutes your acceptance of such modifications. It is your sole responsibility 
            to periodically review these Terms for any changes.
          </p>
        </section>

        <section>
          <h2>2. Eligibility for Use of the Platform</h2>
          <p>
            By accessing or using the Platform, you represent and warrant that you are at least 18 years of age or older, 
            or that you are of legal age in your jurisdiction to form a legally binding contract. You further represent 
            and warrant that you are fully able and competent to enter into and comply with these Terms, and that you 
            are not prohibited by law from accessing or using the Platform. Boon retains the absolute right to deny access 
            to the Platform or Services to any individual or entity at its sole discretion, without prior notice or explanation.
          </p>
        </section>

        <section>
          <h2>3. Use of the Platform and Prohibited Conduct</h2>
          <p>
            You acknowledge and agree that your access to and use of the Platform and Services are strictly limited to 
            lawful purposes, and that you will not, under any circumstances, engage in any of the following prohibited activities:
          </p>
          <ul>
            <li>Attempting to gain unauthorized access to any portion of the Platform, including but not limited to user accounts, databases, servers, or networks connected to the Platform;</li>
            <li>Uploading, transmitting, distributing, or otherwise making available any content, material, or information that is unlawful, fraudulent, defamatory, abusive, obscene, or in violation of any third-party rights;</li>
            <li>Engaging in any activities that violate any applicable local, state, national, or international laws, regulations, ordinances, or treaties;</li>
            <li>Using the Platform to advertise, promote, or sell any products or services without prior written permission from Boon;</li>
            <li>Interfering with or disrupting the proper operation of the Platform, or attempting to disrupt the Services in any manner, including but not limited to introducing viruses, worms, malware, or other malicious code.</li>
          </ul>
          <p>
            Boon retains the absolute and sole discretion to investigate, prosecute, and take any legal or equitable 
            action, including but not limited to account suspension or termination, against any individual or entity 
            suspected of violating these Terms, or engaging in any prohibited conduct, as determined by Boon in its sole discretion.
          </p>
        </section>

        <section>
          <h2>4. Account Responsibility</h2>
          <p>
            In order to access certain features of the Platform, you may be required to create an account. By doing so, 
            you agree that the information you provide during the registration process is accurate, complete, and current 
            at all times. You are solely responsible for maintaining the confidentiality and security of your account 
            credentials, including but not limited to your username and password. You acknowledge and agree that you are 
            fully responsible for any and all activities that occur under your account, whether or not authorized by you. 
            You further agree to immediately notify Boon of any unauthorized access to or use of your account or password.
          </p>
          <p>
            Boon shall not be liable for any loss or damage arising from your failure to comply with the requirements set 
            forth in this section. You may not transfer, assign, or sell your account to any third party, and Boon 
            reserves the right to terminate your account at any time, for any reason, without prior notice.
          </p>
        </section>

        <section>
          <h2>5. Intellectual Property and Proprietary Rights</h2>
          <p>
            The Platform, including but not limited to all text, graphics, logos, icons, images, audio clips, video 
            clips, software, code, and other content and materials, as well as the design, structure, and arrangement of 
            such content, is the exclusive property of Boon or its licensors, and is protected by United States and 
            international copyright, trademark, patent, trade secret, and other intellectual property or proprietary 
            rights laws.
          </p>
          <p>
            Except as expressly permitted by Boon in writing, you are prohibited from copying, distributing, modifying, 
            transmitting, displaying, performing, reproducing, publishing, licensing, creating derivative works from, 
            or using any content from the Platform for commercial or public purposes. Any unauthorized use of the Platform 
            or its content may violate applicable intellectual property laws and could result in civil or criminal 
            liability.
          </p>
        </section>

        <section>
          <h2>6. Disclaimers and Limitation of Liability</h2>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, BOON, ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND 
            THIRD-PARTY SERVICE PROVIDERS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO 
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE PLATFORM 
            AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND.
          </p>
          <p>
            IN NO EVENT SHALL BOON, ITS AFFILIATES, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR 
            AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES 
            ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO OR USE OF THE PLATFORM, EVEN IF ADVISED OF THE 
            POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF LIABILITY SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY 
            APPLICABLE LAW, REGARDLESS OF THE THEORY OF LIABILITY, INCLUDING BUT NOT LIMITED TO CONTRACT, TORT, 
            NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE.
          </p>
        </section>

        <section>
          <h2>7. Indemnification</h2>
          <p>
            You agree to defend, indemnify, and hold harmless Boon, its affiliates, licensors, and their respective 
            officers, directors, employees, agents, and representatives from and against any and all claims, 
            liabilities, damages, losses, costs, or expenses, including reasonable attorneys’ fees, arising out of or 
            in connection with your access to or use of the Platform, your violation of these Terms, or your violation 
            of any third-party rights.
          </p>
        </section>

        <section>
          <h2>8. Termination and Suspension</h2>
          <p>
            Boon reserves the right, at its sole discretion, to terminate, suspend, or restrict your access to all or any 
            portion of the Platform or Services, at any time and for any reason, including but not limited to your violation 
            of these Terms, without prior notice or liability. Upon termination of your account, your right to use the 
            Platform will immediately cease, and Boon may take any legal action it deems necessary to enforce its rights.
          </p>
        </section>

        <section>
          <h2>9. Miscellaneous Provisions</h2>
          <p>
            These Terms constitute the entire agreement between you and Boon regarding your use of the Platform and supersede 
            all prior agreements and understandings, whether written or oral, between you and Boon with respect to such subject matter.
          </p>
          <p>
            If any provision of these Terms is found to be invalid, illegal, or unenforceable for any reason, the remaining 
            provisions of these Terms shall remain in full force and effect. The failure of Boon to enforce any right or 
            provision of these Terms will not constitute a waiver of such right or provision.
          </p>
        </section>

        <section>
          <h2>10. Governing Law</h2>
          <p>
            These Terms shall be governed by, and construed in accordance with, the laws of [Your Jurisdiction], without 
            regard to its conflict of law principles. Any disputes arising out of or relating to these Terms or your use 
            of the Platform shall be subject to the exclusive jurisdiction of the courts located in [Your Jurisdiction].
          </p>
        </section>

        <section>
          <h2>11. Contact Us</h2>
          <p>
            If you have any questions, concerns, or inquiries regarding these Terms, you may contact us via email at 
            support@boon.com. We will make reasonable efforts to respond to your queries in a timely manner.
          </p>
        </section>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default TermsOfService;
