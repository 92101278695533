import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Assuming Firestore setup is in place
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; // To get the authenticated user
import { Link } from 'react-router-dom';
import heroImage from '../assets/images/hero-background.jpg';
import gravelImage from '../assets/images/gravel-background.jpg';
// import imageCompression from 'browser-image-compression';
import '../styles/HomeFeed.css'; // Custom CSS for this component



function HomeFeed() {
  const [events, setEvents] = useState([]);
  const [recommendedCommunities, setRecommendedCommunities] = useState([]);
  const [recommendedEvents, setRecommendedEvents] = useState([]);
  const [recommendedUsers, setRecommendedUsers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Define isMenuOpen state

  const auth = getAuth();
  const currentUser = auth.currentUser;
  useEffect(() => {
    document.title = 'Feed'; // Set the new title
  }, []); // Runs only once when the component mounts

  useEffect(() => {
    if (currentUser) {
      fetchFollowedEvents();
      fetchRecommendedCommunities();
      fetchRecommendedEvents();
      fetchRecommendedUsers();
    }
  }, [currentUser]);
  const findTagSimilarity = (tags1, tags2) => {
    if (!tags1 || !tags2) return 0;
    const set1 = new Set(tags1);
    const set2 = new Set(tags2);
    const intersection = [...set1].filter(tag => set2.has(tag));
    return intersection.length;
  };
  // Fetch events from users that the current user follows
  const fetchFollowedEvents = async () => {
    try {
      const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', currentUser.uid)));
      const userData = userDoc.docs[0]?.data();
      const followingUserIds = userData?.following || [];

      if (followingUserIds.length > 0) {
        const eventsQuery = query(collection(db, 'events'), where('uid', 'in', followingUserIds));
        const eventsSnapshot = await getDocs(eventsQuery);
        const eventsList = eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

              // Sort events by similarity in descending order
      // const sortedEvents = eventRecommendations.sort((a, b) => b.similarity - a.similarity);
  
      // **Limit to top 12 events**
      const top12Events = eventsList.slice(0, 12);


        setEvents(top12Events);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.error('Error fetching followed events:', error);
    }
  };

  // Fetch recommended communities based on user's interests and tags
  const fetchRecommendedCommunities = async () => {
    try {
      const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', currentUser.uid)));
      const userData = userDoc.docs[0]?.data();
      const userInterests = userData?.interests || [];

      const communitiesSnapshot = await getDocs(collection(db, 'communities'));
      const communitiesList = communitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const eventsSnapshot = await getDocs(collection(db, 'events'));
      const eventsList = eventsSnapshot.docs.map(doc => doc.data());

      const communityRecommendations = communitiesList.map(community => {
        let maxSimilarity = 0;
        eventsList.forEach(event => {
          const similarity = findTagSimilarity(community.tags, event.tags);
          if (similarity > maxSimilarity) maxSimilarity = similarity;
        });
        const interestSimilarity = findTagSimilarity(community.tags, userInterests);
        maxSimilarity += interestSimilarity;
        return { ...community, similarity: maxSimilarity };
      });

      const sortedCommunities = communityRecommendations.sort((a, b) => b.similarity - a.similarity);

         // Sort events by similarity in descending order
        //  const sortedEvents = eventRecommendations.sort((a, b) => b.similarity - a.similarity);
  
        //  // **Limit to top 12 events**
         const top12sortedCommunities = sortedCommunities.slice(0, 12);
     
        //  // Update the state with the top 12 recommended events
        //  setRecommendedEvents(top12Events);

      setRecommendedCommunities(top12sortedCommunities);

    } catch (error) {
      console.error('Error fetching recommended communities:', error);
    }
  };

  // Fetch recommended events based on interests and tags
  // Fetch recommended events based on shared interests and hobbies
  const fetchRecommendedEvents = async () => {
    try {
      // Fetch the current user's data
      const userQuery = query(
        collection(db, 'users'),
        where('uid', '==', currentUser.uid)
      );
      const userSnapshot = await getDocs(userQuery);
      const userData = userSnapshot.docs[0]?.data();
      const userInterests = userData?.interests || [];
      const userHobbies = userData?.hobbies || [];
  
      // Fetch all events
      const eventsSnapshot = await getDocs(collection(db, 'events'));
      const eventsList = eventsSnapshot.docs.map((eventDocSnap) => {
        const eventData = eventDocSnap.data();
  
        return {
          id: eventDocSnap.id,
          EventDate: new Date(eventData.EventDate),
          postDescription: eventData.postDescription,
          eventBio: eventData.eventBio,
          postPhoto: eventData.postPhoto || null, // Directly use the photo without compression
          attendees: eventData.attendees?.length || 0,
          views: eventData.views?.length || 0,
          location: eventData.postLocation?.name || 'Unknown location',
          city: eventData.postLocation?.city || 'Unknown city',
          interests: eventData.interests || [],
          hobbies: eventData.hobbies || [],
        };
      });
  
      // Calculate similarity scores
      const eventRecommendations = eventsList.map((event) => {
        const interestSimilarity = findTagSimilarity(event.interests, userInterests);
        const hobbySimilarity = findTagSimilarity(event.hobbies, userHobbies);
        const totalSimilarity = interestSimilarity + hobbySimilarity;
        return { ...event, similarity: totalSimilarity };
      });
  
      // Sort events by similarity in descending order
      const sortedEvents = eventRecommendations.sort((a, b) => b.similarity - a.similarity);
  
      // **Limit to top 12 events**
      const top12Events = sortedEvents.slice(0, 12);
  
      // Update the state with the top 12 recommended events
      setRecommendedEvents(top12Events);
  
    } catch (error) {
      console.error('Error fetching recommended events:', error);
    }
  };
  
  


  // Fetch recommended users based on shared interests and hobbies
  const fetchRecommendedUsers = async () => {
    try {
      const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', currentUser.uid)));
      const userData = userDoc.docs[0]?.data();
      const userInterests = userData?.interests || [];
      const userHobbies = userData?.hobbies || [];

      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersList = usersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))

    

        .filter(user => user.uid !== currentUser.uid);

      const userRecommendations = usersList.map(user => {
        const interestSimilarity = findTagSimilarity(user.interests, userInterests);
        const hobbySimilarity = findTagSimilarity(user.hobbies, userHobbies);
        const totalSimilarity = interestSimilarity + hobbySimilarity;
        return { ...user, similarity: totalSimilarity };
      });

      const sortedUsers = userRecommendations.sort((a, b) => b.similarity - a.similarity);
      setRecommendedUsers(sortedUsers);

    } catch (error) {
      console.error('Error fetching recommended users:', error);
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state
  };
  return (
    <div>

<header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>

        <div className="header-right">
        <Link to='/messages' className="nav-link">
          <p>DMs</p>
        </Link>
        <Link to='/communities' className="nav-link">
          <p>Community</p>
        </Link>
        <Link to='/connections' className="nav-link">
          <p>Connect</p>
        </Link>

        <Link to={`/profile/${currentUser.uid}`} className="nav-link">
          <p>Profile</p>
        </Link>
        </div>
      </header>
    
      
    <div className="homefeed-container">
 
 
{/* Fullscreen Overlay Menu */}
<div className={`overlay ${isMenuOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleMenu}>&times;</button>
        <div className="menu-grid">
          <Link to="/networking" className="menu-item">
          <img src={gravelImage} alt="Network" />
            <p>Networking</p>
          </Link>
          <Link to="/events" className="menu-item">
          <img src={gravelImage} alt="Network" />
            <p>Events</p>
          </Link>
          <Link to="/profile" className="menu-item">
          <img src={gravelImage} alt="Network" />
            <p>Profile</p>
          </Link>
          <Link to={`/profile/${currentUser.uid}`} className="menu-item">
          <img src={gravelImage} alt="Dashboard" />
          <p>Profile</p>
        </Link>
          {/* Add more links here */}
        </div>
      </div>

      {/* recommendedEvents */}
      <h2>Events from Users You Follow</h2>
      <div className="events-grid">
        {events.length > 0 ? events.map((event, index) => (
           <div
           key={event.id}
           className='full-card'  // Add 'appear' class when ready
           style={{
             backgroundImage: `url(${event.postPhoto})`,
             backgroundSize: 'cover',
             backgroundPosition: 'center',
             position: 'relative',
             backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent black
             borderRadius: '15px', // Add border radius here
             overflow: 'hidden',
                       }}
         >
           <Link to={`/event/${event.id}`} className="event-link">
             {/* Add a semi-transparent overlay for better readability */}
             <div className="event-overlay"></div>
   
             {/* Event details */}
             <div className="event-details" style={{ position: 'relative', zIndex: 2 }}>
             <h2 className="event-title">
              {event.postDescription ? `${event.postDescription.slice(0, 20)}...` : 'No title available'}
            </h2>
            <p className="event-description">
              {event.eventBio ? `${event.eventBio.slice(0, 20)}...` : 'No description available'}
            </p>
            <p className="event-price">
              Price: ${event.price ? event.price : 'N/A'}
            </p>
  
               <p className="event-date">
                 Date: {event.EventDate ? event.EventDate.toLocaleDateString() : 'TBD'}
               </p>
             </div>
           </Link>
         </div>
        )) : <p>No events available.</p>}
      </div>

      <h2>Recommended Events</h2>
      <div className="events-grid">
        {recommendedEvents.length > 0 ? recommendedEvents.map((event, index) => (
         <div
         key={event.id}
         className='full-card'  // Add 'appear' class when ready
         style={{
           backgroundImage: `url(${event.postPhoto})`,
           backgroundSize: 'cover',
           backgroundPosition: 'center',
           position: 'relative',
           backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent black
           borderRadius: '15px', // Add border radius here
           overflow: 'hidden',
         }}
       >
         <Link to={`/event/${event.id}`} className="event-link">
           {/* Add a semi-transparent overlay for better readability */}
           <div className="event-overlay"></div>
 
           {/* Event details */}
           <div className="event-details" style={{ position: 'relative', zIndex: 2 }}>
           <h2 className="event-title">
            {event.postDescription ? `${event.postDescription.slice(0, 20)}...` : 'No title available'}
          </h2>
          <p className="event-description">
            {event.eventBio ? `${event.eventBio.slice(0, 20)}...` : 'No description available'}
          </p>
          <p className="event-price">
            Price: ${event.price ? event.price : 'N/A'}
          </p>

             <p className="event-date">
             Date: {event.EventDate ? event.EventDate.toLocaleDateString() : 'TBD'}
             </p>
           </div>
         </Link>
       </div>
        )) : <p>No events available.</p>}
      </div>


      <h2>Recommended Communities</h2>
<div className="events-grid">
  {recommendedCommunities.length > 0 ? recommendedCommunities.map((community, index) => (
    <div
      key={index}
      className="full-card"
      style={{
        backgroundImage: `url(${community.photo || 'fallback-image-url.jpg'})`, // Use fallback image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '15px', // Add border radius here
        overflow: 'hidden',
      }}
    >
      <Link to={`/community/${community.id}`} className="event-link">
        <div className="event-overlay"></div>
        <div className="event-details">
          <h2 className="event-title">{community.name || 'No name available'}</h2>
          <p className="event-description">{community.description || 'No description available'}</p>
        </div>
      </Link>
    </div>
  )) : <p>No communities recommended.</p>}
</div>





    </div>



<footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default HomeFeed;
