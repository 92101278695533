import React, { useEffect, useState } from 'react';
import '../styles/Networking.css'; // Reusing the same styles
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

const MeetPeople = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Meet People'; // Set the new title
  }, []); // Runs only once when the component mounts


  return (
    <div className="networking-container"> {/* Reusing the general container style */}

    {currentUser?.uid ? (
                <header className="header">
                <div className="header-left">
                  <h1 className="company-name">Boon</h1>
                </div>
        
                <div className="header-right">
                <Link to='/messages' className="nav-link">
                  <p>DMs</p>
                </Link>
                <Link to='/communities' className="nav-link">
                  <p>Community</p>
                </Link>
                <Link to='/connections' className="nav-link">
                  <p>Connect</p>
                </Link>
        
                <Link to={`/profile/${currentUser.uid}`} className="nav-link">
                  <p>Profile</p>
                </Link>
                </div>
              </header>
        ) : (
          <header className="header">
            <div className="header-left">
              <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
                <h1 className="company-name">Boon</h1>
              </Link>
            </div>
            <div className="header-right">
              <Link to="/login" className="nav-link">Join</Link>
            </div>
          </header>
        )}


      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Expand Your Social Circle</h1>
          <p>Discover New Connections in Your City, Industry, or Interest Group</p>
          <a href="#get-started" className="cta-button">Start Meeting People</a>
        </div>
      </section>

      {/* Opportunities Section */}
      <section className="benefits-section"> {/* Reusing benefits-section style */}
        <h2 className="section-title">Discover Opportunities to Connect</h2>
        <div className="benefits-grid"> {/* Using the same grid layout */}
          <div className="benefit-item">
            <h3>New City Adventures</h3>
            <p>Moving to a new city or just visiting? Connect with locals and fellow newcomers to explore together.</p>
            <p className="example">Example: Join a "New in Town" meetup to discover hidden gems in your area.</p>
          </div>
          <div className="benefit-item">
            <h3>Professional Networking</h3>
            <p>Expand your professional circle within your industry or explore new career paths.</p>
            <p className="example">Example: Attend industry-specific virtual mixers to meet peers and potential mentors.</p>
          </div>
          <div className="benefit-item">
            <h3>Interest-Based Connections</h3>
            <p>Find people who share your passions, from niche hobbies to popular interests.</p>
            <p className="example">Example: Join a book club or a local hiking group to meet like-minded individuals.</p>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="success-stories-section">
        <h2 className="section-title">Real Connection Stories</h2>
        <div className="success-story">
          <p>"I moved to a new city and found an amazing group of friends through Boon's local events. It made the transition so much easier!"</p>
          <p>- Alex, Software Developer</p>
        </div>
        <div className="success-story">
          <p>"Boon helped me connect with fellow entrepreneurs in my industry. These connections have been invaluable for my business growth."</p>
          <p>- Sophia, Startup Founder</p>
        </div>
      </section>

      {/* How to Meet People Section */}
      <section className="how-it-works-section"> {/* Reusing how-it-works-section */}
        <h2 className="section-title">How to Meet People on Boon</h2>
        <div className="how-it-works-grid">
          <div className="how-it-works-item">
            <h3>Local Events</h3>
            <p>Discover and join events in your area, from casual meetups to professional networking sessions.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Interest Groups</h3>
            <p>Join or create groups based on shared interests, hobbies, or professional fields.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Virtual Connections</h3>
            <p>Connect with people worldwide through our virtual events and chat features.</p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="how-it-works-section"> {/* Reusing how-it-works-section for consistency */}
        <h2 className="section-title">Features to Help You Connect</h2>
        <div className="how-it-works-grid"> {/* Using same grid layout */}
          <div className="how-it-works-item">
            <h3>Smart Matching</h3>
            <p>Our algorithm suggests connections based on your interests, location, and professional background.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Safe Meetups</h3>
            <p>Verified users and secure meeting spots for in-person connections.</p>
          </div>
          <div className="how-it-works-item">
            <h3>Icebreakers</h3>
            <p>Conversation starters and group activities to make initial interactions smoother.</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section id="get-started" className="cta-section">
        <h2>Ready to Expand Your Circle?</h2>
        <a href="/signup" className="cta-button">Join Boon Today</a>
      </section>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>



    </div>
  );
};

export default MeetPeople;
