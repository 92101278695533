import { db } from '../firebase';  // Firebase Firestore config
import React, { useEffect, useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import '../styles/CreateEvent.css'; // Assuming you'll style this page
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user
function CreateEvent() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [price, setPrice] = useState('');
  const [imageUrl, setImageUrl] = useState(''); // If you want to upload an image URL for the event

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Create an Event'; // Set the new title
  }, []); // Runs only once when the component mounts


  // Handle event creation
  const handleCreateEvent = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    if (!title || !description || !eventDate || !price) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'events'), {
        title,
        description,
        eventDate: new Date(eventDate), // Store eventDate as a Date object
        price: parseFloat(price),
        imageUrl, // Add the image URL if it's provided
        createdAt: new Date(),
      });
      console.log('Event created with ID: ', docRef.id);
      setSuccessMessage('Event successfully created!');
      setTitle('');
      setDescription('');
      setEventDate('');
      setPrice('');
      setImageUrl('');
    } catch (error) {
      console.error('Error creating event: ', error);
      setErrorMessage('Failed to create event. Please try again.');
    }
  };

  return (
    <div className="create-event-container">
  <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>

        <div className="header-right">
        <Link to='/messages' className="nav-link">
          <p>DMs</p>
        </Link>
        <Link to='/communities' className="nav-link">
          <p>Community</p>
        </Link>
        <Link to='/connections' className="nav-link">
          <p>Connect</p>
        </Link>

        <Link to={`/profile/${currentUser.uid}`} className="nav-link">
          <p>Profile</p>
        </Link>
        </div>
      </header>



      <h1>Create New Event</h1>
      <form onSubmit={handleCreateEvent} className="create-event-form">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Event Title"
          required
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Event Description"
          required
        />
        <input
          type="datetime-local"
          value={eventDate}
          onChange={(e) => setEventDate(e.target.value)}
          placeholder="Event Date"
          required
        />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Price"
          required
        />
        <input
          type="text"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          placeholder="Image URL (optional)"
        />
        <button type="submit">Create Event</button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default CreateEvent;
