import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import CreateEvent from './pages/CreateEvent';
import EventDetails from './pages/EventDetails';
import UserProfile from './pages/UserDetails';
import LoginSignup from './pages/LoginSignup';
import SettingsPage from './pages/SettingsPage'; // Import the Settings Page
import CommunitiesPage from './pages/CommunitiesPage'; // Import the Communities Page
import CommunityDetails from './pages/CommunityDetails'; // Import the Community Details Page
import CreateCommunity from './pages/CreateCommunity'; // Import Create Community page
import AnalyticsDashboard from './pages/AnalyticsDashboard'; // Import the Analytics Dashboard Page
import EventMarketing from './pages/EventMarketing'; // Import the Event Marketing Page
import AttendeeManagement from './pages/AttendeeManagement'; // Import the Attendee Management Page
import VenueManagement from './pages/VenueManagement'; // Import the Venue Management Page
import TicketingManagement from './pages/TicketingManagement'; // Import the Ticketing Management Page
import Connections from './pages/Connections'; // Import the Connections page
import InviteToHangout from './pages/InviteToHangout';
import HangoutResponse from './pages/HangoutResponse';
import DirectMessages from './pages/DirectMessages';
import Home3d from './pages/Home3d';
import Networking from './pages/Networking'; // Adjust the path if needed
import CreatorPage from './pages/CreatorPage'; // Your Creator page component
import TicketDetails from './pages/TicketDetails'; // Import the TicketDetails component
import UserDashboard from './pages/Profile'; // Import the TicketDetails component
// Authentication context or function to check if user is logged in
import HomeFeed from './pages/HomeFeed'; // Your HomeFeed component
import HeroNetwork from './pages/HeroNetwork';
import BrandBuilding from './pages/BrandBuilding';
import MeetPeople from './pages/MeetPeople';
import Community from './pages/Community';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutUs from './pages/AboutUs';
import Careers from './pages/Careers';
import Support from './pages/Support'; // Assuming Support is saved as Support.js
import FAQ from './pages/FAQ'; // Assuming you save the FAQ component in the components folder

import { useAuth } from './authContext'; // Replace this with your actual authentication hook/context



function App() {
  const { currentUser } = useAuth(); // Assuming you have a context/hook for authentication

  // Protected Route Wrapper
  const PrivateRoute = ({ element, ...rest }) => {
    return currentUser ? element : <Navigate to="/login" />;
  };


  return (
    <Router>
      <Routes>
          {/* public pages */}
        <Route path="/" element={<Home />} />
        <Route path="/login" exact element={<LoginSignup />} />
        <Route path="/signup" exact element={<LoginSignup />} />
        <Route path="/tos" exact element={<TermsOfService />} />
        <Route path="/privacy" exact element={<PrivacyPolicy />} />
        <Route path="/about" exact element={<AboutUs />} />
        <Route path="/careers" exact element={<Careers />} />
        <Route path="/support" exact element={<Support />} />
        <Route path="/faq" element={<FAQ />} />

        <Route path="/event/:eventId" element={<EventDetails />} /> {/* Dynamic route */}
        <Route path="/home3d" element={<Home3d />} />
        <Route path="/networking" element={<Networking/>} />
        <Route path="/creator" element={<CreatorPage />} /> {/* Dynamic Creator Profile Route */}
        <Route path="/tickets/:ticketId" element={<TicketDetails />} /> {/* New route for ticket details */}
        <Route path="/hero" element={<HeroNetwork/>} />
        <Route path="/build-brand" element={<BrandBuilding/>} />
        <Route path="/meet-people" element={<MeetPeople/>} />
        <Route path="/community" element={<Community/>} />

        {/* authenticated pages */}
        <Route path="/profile/:userId" element={<PrivateRoute element={<UserDashboard userId={currentUser?.uid} />} />} />
        {/* <Route path="/profile/:userId" element={<PrivateRoute element={<UserProfile />} />} /> */}
        <Route path="/settings" element={<PrivateRoute element={<SettingsPage  userId={currentUser?.uid}/>} />} />
        <Route path="/homefeed" element={<PrivateRoute element={<HomeFeed userId={currentUser?.uid} />} />} />
        <Route path="/ticketing-management" element={<PrivateRoute element={<TicketingManagement  userId={currentUser?.uid}  />} />} />
        <Route path="/connections" element={<PrivateRoute element={<Connections  userId={currentUser?.uid} />} />} />
        <Route path="/invite/:userId" element={<PrivateRoute element={<InviteToHangout  userId={currentUser?.uid} />} />} />
        <Route path="/respond/:inviteId" element={<PrivateRoute element={<HangoutResponse  userId={currentUser?.uid} />} />} />
        <Route path="/messages" element={<PrivateRoute element={<DirectMessages  userId={currentUser?.uid} />} />} />
        <Route path="/userdetails" element={<PrivateRoute element={<UserProfile userId={currentUser?.uid} />} />} />
        <Route path="/userdetails/:userId" element={<PrivateRoute element={<UserProfile  userId={currentUser?.uid} />} />} />
        <Route path="/communities" element={<PrivateRoute element={<CommunitiesPage  userId={currentUser?.uid} />} />} />
        <Route path="/communities/:communityId" element={<PrivateRoute element={<CommunityDetails  userId={currentUser?.uid} />} />} />
        <Route path="/create-event" element={<PrivateRoute element={<CreateEvent  userId={currentUser?.uid} />} />} />
        <Route path="/create-community" element={<PrivateRoute element={<CreateCommunity  userId={currentUser?.uid} />} />} />
        <Route path="/analytics-dashboard" element={<PrivateRoute element={<AnalyticsDashboard  userId={currentUser?.uid} />} />} />
        <Route path="/event-marketing" element={<PrivateRoute element={<EventMarketing userId={currentUser?.uid}  />} />} />
        <Route path="/attendee-management" element={<PrivateRoute element={<AttendeeManagement  userId={currentUser?.uid} />} />} />
        <Route path="/venue-management" element={<PrivateRoute element={<VenueManagement userId={currentUser?.uid}  />} />} />

      </Routes>
    </Router>
  );
}

export default App;