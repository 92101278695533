// here we get the details fo a community, members, share community, invite users, leave a community, post to 
// the community wall etc

import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Firebase Firestore instance
import { query, where, collection, doc, getDoc, updateDoc, arrayUnion, arrayRemove, addDoc, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth
import '../styles/CommunityDetails.css'; // Custom CSS
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal'; // Import Modal

function CommunityDetails() {
  const [community, setCommunity] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState('');
  const [posts, setPosts] = useState([]);
  const [image, setImage] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { communityId } = useParams();
  const auth = getAuth();
  const currentUser = auth.currentUser;


  useEffect(() => {
    document.title = community?.name ? community.name : 'Community Details'; // Set the new title
  }, []); // Runs only once when the component mounts
  useEffect(() => {
    // Monitor Firebase Authentication state and get the current user
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserId(user.uid); // Set the current user ID
      } else {
        setCurrentUserId(null); // No user is signed in
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Fetch community details
    const fetchCommunityDetails = async () => {
      const communityRef = doc(db, 'communities', communityId);
      const communityDoc = await getDoc(communityRef);
      if (communityDoc.exists()) {
        setCommunity(communityDoc.data());
        if (currentUserId) {
          setIsMember(communityDoc.data().members.includes(currentUserId));
        }
      }
    };

    // Fetch posts from the community
    const fetchPosts = async () => {
      const postsRef = collection(db, 'community posts');
      const q = query(postsRef, where('communityID', '==', communityId));
      const postsSnapshot = await getDocs(q);
      if (postsSnapshot.empty) {
        setPosts(null);
        return;
      }
      const postsList = postsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postsList);
    };

    if (currentUserId) {
      fetchCommunityDetails();
      fetchPosts();
    }
  }, [communityId, currentUserId]);

  // Join community
  const joinCommunity = async () => {
    const communityRef = doc(db, 'communities', communityId);
    await updateDoc(communityRef, {
      members: arrayUnion(currentUserId),
    });
    setIsMember(true);
  };

  // Leave community
  const leaveCommunity = async () => {
    const communityRef = doc(db, 'communities', communityId);
    await updateDoc(communityRef, {
      members: arrayRemove(currentUserId),
    });
    setIsMember(false);
  };

  // Post a status update
  const postStatusUpdate = async () => {
    const postsRef = collection(db, 'communities', communityId, 'posts');
    await addDoc(postsRef, {
      text: statusUpdate,
      imageUrl: image,
      postedBy: currentUserId,
      createdAt: new Date(),
    });
    setStatusUpdate('');
    setImage(null); 
    const postsSnapshot = await getDocs(postsRef);
    const postsList = postsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPosts(postsList);
  };

  // Handle image upload (modernized)
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file)); 
  };

  // Modal handling for sharing the community
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // Simulate search logic (fetch users from Firestore based on input)
    setSearchResults([/* example user results */]);
  };

  const handleShareCommunity = (userId) => {
    // Logic to send the community to the selected user
    alert(`Shared community with user ${userId}`);
    closeModal();
  };

  return (
    <div> 
   <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>

        <div className="header-right">
        <Link to='/messages' className="nav-link">
          <p>DMs</p>
        </Link>
        <Link to='/communities' className="nav-link">
          <p>Community</p>
        </Link>
        <Link to='/connections' className="nav-link">
          <p>Connect</p>
        </Link>

        <Link to={`/profile/${currentUser.uid}`} className="nav-link">
          <p>Profile</p>
        </Link>
        </div>
      </header>
    <div className="community-details-container">
     

      {community ? (
        <>
          <div className="community-header">
            {community.photoUrl && <img src={community.photoUrl} alt="Community Banner" className="community-banner" />}
            <h1>{community?.name}</h1>
            <p>{community.description}</p>
            <div className="community-actions">
              {isMember ? (
                <button onClick={leaveCommunity}>Leave</button>
              ) : (
                <button onClick={joinCommunity}>Join</button>
              )}
              <button onClick={openModal}>Share</button>
              <button onClick={() => alert('Inviting others...')}>Invite</button>
            </div>
          </div>

          {/* Status Update Input */}
          {isMember && (
            <div className="status-update">
              <textarea
                value={statusUpdate}
                onChange={(e) => setStatusUpdate(e.target.value)}
                placeholder="Post a status update..."
                style={{ width: '90%', height: '200px', overflow: 'auto' }}
              />
              <div className="upload-section">
                <label className="custom-file-upload">
                  <input type="file" onChange={handleImageChange} />
                  Upload Photo
                </label>
                {image && <img src={image} alt="Preview" className="image-preview" />}
              </div>
              <button onClick={postStatusUpdate}>Post</button>
            </div>
          )}

          {/* Community Posts */}
          <div className="community-posts">
            <h2>Community Posts</h2>
            {posts ? (
              posts.map((post) => (
                <div className="post-card" key={post.id}>
                  <p>{post.text}</p>
                  {post.imageUrl && <img src={post.imageUrl} alt="Post" className="post-image" />}
                  <small>Posted by {post.postedBy}</small>
                </div>
              ))
            ) : (
              <p>No posts yet.</p>
            )}
          </div>
        </>
      ) : (
        <p>Loading community details...</p>
      )}

      {/* Modal for Sharing Community */}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Share Community">
        <h2>Share Community</h2>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search for a user..."
        />
        <ul>
          {searchResults.map((result) => (
            <li key={result.id} onClick={() => handleShareCommunity(result.id)}>
              {result?.name}
            </li>
          ))}
        </ul>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


    </div>
  );
}

export default CommunityDetails;
