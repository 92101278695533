import React, { useEffect, useState } from 'react';
import '../styles/AttendeeManagement.css'; // Custom CSS for styling
import { Link } from 'react-router-dom';

function AttendeeManagement() {
  const [attendees, setAttendees] = useState([]);
  useEffect(() => {
    document.title = 'Attendee Management'; // Set the new title
  }, []); // Runs only once when the component mounts
  useEffect(() => {
    // Fetch attendee data from API or database
    const fetchAttendees = async () => {
      // Simulate fetching data
      const attendeeList = [
        { id: 1, name: 'John Doe', email: 'john@example.com', status: 'Confirmed' },
        { id: 2, name: 'Jane Smith', email: 'jane@example.com', status: 'Pending' },
      ];
      setAttendees(attendeeList);
    };

    fetchAttendees();
  }, []);

  return (
    <div className="attendee-management">


<header className="header">
  <div className="header-left">
    <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
      <h1 className="company-name">Boon</h1>
    </Link>
  </div>
  <div className="header-right">
    <Link to="/login" className="nav-link">Login</Link>
  </div>
</header>



      <h1>Attendee Management</h1>
      <div className="attendee-list">
        {attendees.map((attendee) => (
          <div key={attendee.id} className="attendee-card">
            <h2>{attendee.name}</h2>
            <p>Email: {attendee.email}</p>
            <p>Status: {attendee.status}</p>
          </div>
        ))}
      </div>




    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>

    </div>
  );
}

export default AttendeeManagement;
