import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Assuming Firebase is initialized correctly
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'; // Import Firestore methods
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // For Firebase Storage
import { storage } from '../firebase'; // Assuming Firebase Storage is initialized
import '../styles/Careers.css'; // Assuming you’ll add custom styling
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function Careers() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Marketing Manager');
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');

  const auth = getAuth();
  const currentUser = auth.currentUser;


  useEffect(() => {
    document.title = 'Brand Building'; // Set the new title
  }, []); // Runs only once when the component mounts
  // Define available roles
  const roles = [
    'Marketing Manager',
    'SEO Specialist',
    'Content Creator',
    'Social Media Manager',
    'Digital Marketing Analyst',
    'Web Developer',
    'Frontend Developer',
    'Backend Developer',
    'Full Stack Developer',
    'UI/UX Designer'
  ];

  // Maximum file size in bytes (500MB)
  const MAX_FILE_SIZE = 500 * 1024 * 1024;

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !role || !resume) {
      setMessage('Please fill in all fields.');
      return;
    }

    // Check file size before proceeding
    if (resume.size > MAX_FILE_SIZE) {
      setMessage('File size exceeds 500MB. Please upload a smaller file.');
      return;
    }

    try {
      // Upload the resume to Firebase Storage
      const resumeRef = ref(storage, `resumes/${resume.name}`);
      await uploadBytes(resumeRef, resume);
      const resumeURL = await getDownloadURL(resumeRef);

      // Save the user details and resume URL to Firestore
      await addDoc(collection(db, 'careers'), {
        name,
        email,
        role,
        resumeURL,
        timestamp: serverTimestamp(), // Add a server timestamp for record-keeping
      });

      // Clear the form and show success message
      setMessage('Application submitted successfully!');
      setName('');
      setEmail('');
      setRole('Marketing Manager');
      setResume(null);
    } catch (error) {
      console.error('Error submitting application: ', error);
      setMessage('Error submitting application. Please try again.');
    }
  };

  return (
    <div>
     

    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}


      <div className="careers-container">
        <h1>Join Our Team</h1>
        <p>We're always looking for talented individuals to join us. Apply for a role that fits your skills and interests!</p>

        <form className="careers-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Enter your full name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email address"
            />
          </div>

          <div className="form-group">
            <label htmlFor="role">Role Applying For</label>
            <select id="role" value={role} onChange={(e) => setRole(e.target.value)} required>
              {roles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="resume">Upload Resume (Max 500MB)</label>
            <input
              type="file"
              id="resume"
              accept=".pdf,.doc,.docx"
              onChange={(e) => setResume(e.target.files[0])}
              required
            />
          </div>

          <button type="submit">Submit Application</button>
        </form>

        {message && <p className="message">{message}</p>}
      </div>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>

    </div>
  );
}

export default Careers;
