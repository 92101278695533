import React, { useEffect, useState } from 'react';
import '../styles/CreatorPage.css'; // Ensure the CSS file is properly linked
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

const CreatorPage = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'Be a Creator'; // Set the new title
  }, []); // Runs only once when the component mounts


  return (
    <div className="creator-page-container">
              

    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}



      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Build, Engage, and Grow</h1>
          <p>Connect with Your Audience Like Never Before</p>
          <a href="/signup" className="cta-button">Get Started Today</a>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="benefits-section">
        <h2>Why Join Boon as a Creator?</h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <h3>Engage Directly with Your Followers</h3>
            <p>
              Personalized communication and interactions help you grow and strengthen your community. Connect with your audience in real time.
            </p>
          </div>
          <div className="benefit-item">
            <h3>Build a Loyal Community</h3>
            <p>
              Offer exclusive content, behind-the-scenes access, and early releases to your most loyal followers. Create a dedicated fanbase that supports your journey.
            </p>
          </div>
          <div className="benefit-item">
            <h3>Monetize Your Audience</h3>
            <p>
              Set up subscriptions, offer paid content, or receive direct support through tips and fan contributions. Boon helps you turn your passion into a sustainable business.
            </p>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="success-stories-section">
        <h2>Real-Life Success Stories</h2>
        <div className="success-story">
          <p>
            “Boon has completely changed how I connect with my audience. The direct messaging feature has allowed me to have meaningful conversations with my top followers, which has helped me grow my personal brand faster than I ever imagined.”
          </p>
          <p><strong>— Jessica D., Lifestyle Influencer</strong></p>
        </div>
        <div className="success-story">
          <p>
            “Thanks to Boon’s subscription features, I’ve been able to offer my supporters exclusive content that they love. This has created a loyal community and provided me with a stable income stream.”
          </p>
          <p><strong>— Mark T., Fitness Coach</strong></p>
        </div>
      </section>

      {/* Tools and Features Section */}
      <section className="tools-section">
        <h2>Tools to Help You Succeed</h2>
        <div className="tools-grid">
          <div className="tool-item">
            <h3>Direct Messaging</h3>
            <p>Send direct messages and interact one-on-one with your followers, building stronger relationships through personal engagement.</p>
          </div>
          <div className="tool-item">
            <h3>Live Events</h3>
            <p>Host live meetups, Q&A sessions, or exclusive events where your audience can engage with you in real-time.</p>
          </div>
          <div className="tool-item">
            <h3>Custom Subscriptions</h3>
            <p>Offer subscription tiers that give your followers access to exclusive content, early releases, and premium experiences.</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2>Ready to Cultivate Your Audience?</h2>
        <a href="/signup" className="cta-button">Join Boon as a Creator</a>
      </section>



    <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>



    </div>
  );
};

export default CreatorPage;
