import React, { useState, useEffect } from 'react';
import '../styles/AboutUs.css'; // Assuming you'll add custom styling
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user

function AboutUs() {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    document.title = 'About Us'; // Set the new title
  }, []); // Runs only once when the component mounts

  return (
    <div className="about-us-container">
      


    {currentUser?.uid ? (
      <header className="header">
        <div className="header-left">
          <h1 className="company-name">Boon</h1>
        </div>
        <div className="header-right">
          <Link to='/messages' className="nav-link">
            <p>DMs</p>
          </Link>
          <Link to='/communities' className="nav-link">
            <p>Community</p>
          </Link>
          <Link to='/connections' className="nav-link">
            <p>Connect</p>
          </Link>
          <Link to={`/profile/${currentUser.uid}`} className="nav-link">
            <p>Profile</p>
          </Link>
        </div>
      </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link">
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}
      
      <section className="about-section">
        <h1>About Us</h1>
        
        <p>
          Boon was founded by Maureese Williams, driven by a deep passion for community building and the desire to create a 
          platform that truly supports personal and professional growth. Maureese was inspired by the idea of providing people 
          with more than just a networking tool—Boon is a place for connection, learning, and fulfillment. Whether it’s helping 
          someone find the right professional contact, join a recreational group, or discover valuable resources for career 
          development, Boon is designed to be the bridge that enables people to achieve their goals.
        </p>
        
        <h2>Our Mission</h2>
        <p>
          At the heart of Boon’s mission is the belief that real people can achieve real success when they have the right 
          community and resources at their fingertips. Our platform is focused on enabling users to build meaningful connections, 
          both professionally and personally. We believe that networking should not only help individuals advance their careers 
          but also enhance their lives by fostering relationships that bring personal fulfillment and growth.
        </p>

        <h2>Inspiration Behind Boon</h2>
        <p>
          Maureese Williams envisioned Boon as a platform where people could come together to share knowledge, collaborate, and 
          grow. He saw the power of bringing like-minded individuals together to create opportunities—whether for fun, learning, 
          or career development. Boon isn’t just about networking; it’s about discovering resources, connecting with people who 
          can help you grow, and using those relationships to better yourself, both personally and professionally.
        </p>

        <h2>What We Offer</h2>
        <p>
          Boon provides users with a range of tools and opportunities to support their journey, whether they’re looking for:
        </p>
        <ul>
          <li>Professional networking opportunities to expand their career horizons</li>
          <li>Recreational groups that help build personal connections and friendships</li>
          <li>Access to resources that support skill development and professional improvement</li>
        </ul>
        <p>
          Our goal is simple: to provide a platform where individuals can not only connect with others but also find the resources 
          they need to grow. Boon is here to help people achieve their professional and personal goals, all while making the 
          process enjoyable and meaningful.
        </p>

        <h2>Why We Do It</h2>
        <p>
          We believe that success isn’t just about climbing the career ladder—it’s about finding fulfillment in the relationships 
          you build and the personal growth you experience along the way. Whether you're looking to enhance your career, make new 
          friends, or discover a new passion, Boon is here to help you take those important steps toward personal and professional 
          fulfillment.
        </p>

        <p className="closing-statement">
          Join us on Boon, where real people connect, collaborate, and create opportunities for success and happiness.
        </p>

        <h2>Meet Our Key Contributors</h2>
        <ul className="contributors-list">
          <li>Shahrukh Mahesar - Video Editor</li>
          <li>Jagwinder Singh - Developer</li>
          <li>Veronica Rivalla - Content Creator</li>
          <li>Serg Dyachenko - Logo Creator/Designer</li>
          <li>Was Zafar - Software Tester</li>
          <li>Antonio Soprano - Content Creator</li>
          <li>Viktor Fediuk - UI/UX Designer</li>
          <li>Vikas Dagar - Developer</li>
        </ul>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <h2>Boon</h2>
            <p>Empowering community building and professional growth.</p>
          </div>
          
          <div className="footer-links">
            <div className="footer-column">
              <h3>Boon</h3>
              <a href="/about">About</a>
              <a href="/careers">Careers</a>
            </div>
            <div className="footer-column">
              <h3>Resources</h3>
              <a href="/support">Support</a>
              <a href="/faq">FAQ</a>
            </div>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p>&copy; 2024 Boon. All Rights Reserved.</p>
          <div className="footer-bottom-links">
            <a href="/privacy">Privacy Policy</a>
            <a href="/tos">Terms of Service</a>
          </div>
          <div className="social-icons">
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default AboutUs;
