import React, { useEffect, useState } from 'react';
import '../styles/PrivacyPolicy.css'; // Assuming you'll add custom styling
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // To get the authenticated user


function PrivacyPolicy() {
  const auth = getAuth();
  const currentUser = auth.currentUser;



  useEffect(() => {
    document.title = 'Privacy'; // Set the new title
  }, []); // Runs only once when the component mounts


  return (
    <div className="privacy-container">

     
    {currentUser?.uid ? (
            <header className="header">
            <div className="header-left">
              <h1 className="company-name">Boon</h1>
            </div>
    
            <div className="header-right">
            <Link to='/messages' className="nav-link">
              <p>DMs</p>
            </Link>
            <Link to='/communities' className="nav-link">
              <p>Community</p>
            </Link>
            <Link to='/connections' className="nav-link">
              <p>Connect</p>
            </Link>
    
            <Link to={`/profile/${currentUser.uid}`} className="nav-link">
              <p>Profile</p>
            </Link>
            </div>
          </header>
    ) : (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="company-link"> {/* Wrap the company name in a Link component */}
            <h1 className="company-name">Boon</h1>
          </Link>
        </div>
        <div className="header-right">
          <Link to="/login" className="nav-link">Join</Link>
        </div>
      </header>
    )}


      <h1>Privacy Policy</h1>
      <p>Last Updated: September 16th, 2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          At Boon (hereinafter referred to as "we," "us," or "our"), we are fully committed to safeguarding and protecting the privacy 
          of all individuals who access and utilize our platform (the "Platform"). This Privacy Policy (the "Policy") outlines 
          the manner in which we collect, use, disclose, and protect your personal information (as defined below). By 
          accessing or using the Platform, you expressly acknowledge and agree to the collection, use, and disclosure of 
          your personal information in accordance with the terms of this Policy. If you do not agree with any part of this 
          Policy, you must immediately cease your use of the Platform.
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <p>
          We collect various types of information from and about users of our Platform. The information we collect may include, 
          but is not limited to, personally identifiable information ("Personal Information"), such as your name, email address, 
          telephone number, mailing address, and other data that can be used to identify you. We may also collect technical and 
          usage data, such as your IP address, browser type, device identifiers, operating system, and the date and time of 
          your interactions with the Platform. Such data may be collected automatically as you navigate through the Platform.
        </p>
        <p>
          We may collect this information through various means, including but not limited to forms, cookies, tracking pixels, 
          and other similar technologies that may automatically collect certain information when you interact with our Platform. 
          By using the Platform, you expressly consent to the collection of this information.
        </p>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>
          The information we collect from you may be used for a variety of purposes, including but not limited to the following:
        </p>
        <ul>
          <li>To provide, operate, and maintain the Platform;</li>
          <li>To process transactions, fulfill requests, and communicate with you regarding your account or events;</li>
          <li>To personalize and enhance your experience on the Platform;</li>
          <li>To monitor and analyze usage and trends in order to improve the functionality and performance of the Platform;</li>
          <li>To comply with applicable laws, regulations, legal processes, or governmental requests;</li>
          <li>To enforce our Terms of Service, protect our legal rights, and prevent fraud or abuse of the Platform;</li>
          <li>For any other purpose that is disclosed to you at the time of collection or with your consent.</li>
        </ul>
        <p>
          We may combine information we collect through the Platform with information we obtain from other sources to 
          enhance the accuracy and relevance of the data used for the purposes listed above.
        </p>
      </section>

      <section>
        <h2>4. Sharing Your Information</h2>
        <p>
          We may share your information with third parties under certain circumstances, including but not limited to the following:
        </p>
        <ul>
          <li>With service providers, contractors, and other third parties who assist us in operating our Platform, conducting 
          our business, or providing services to you, provided that these third parties agree to keep your information confidential;</li>
          <li>To comply with any legal obligation, including but not limited to responding to subpoenas, court orders, or other 
          legal processes;</li>
          <li>To protect and defend our legal rights or property, and to protect the safety of our users or the public as required 
          or permitted by law;</li>
          <li>As part of a merger, acquisition, restructuring, sale, or transfer of assets, where your information may be disclosed 
          as part of the transaction;</li>
          <li>With your consent or at your direction.</li>
        </ul>
        <p>
          We do not sell, rent, or otherwise provide your Personal Information to third parties for their direct marketing purposes 
          without your explicit consent.
        </p>
      </section>

      <section>
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          We, along with third-party service providers, may use cookies, web beacons, and other tracking technologies to collect 
          information about your browsing activities, such as pages visited, links clicked, and other actions taken on the Platform. 
          These technologies help us understand how you use the Platform and allow us to tailor and improve your experience.
        </p>
        <p>
          You can control the use of cookies through your browser settings. However, please note that disabling cookies may 
          impact your experience on the Platform and certain features may not function as intended.
        </p>
      </section>

      <section>
        <h2>6. Data Retention</h2>
        <p>
          We retain your Personal Information only for as long as necessary to fulfill the purposes for which it was collected 
          or as required by applicable laws and regulations. The length of time we retain your information may vary depending on 
          the nature of the information and the reasons for which it was collected. We may retain certain data for longer periods 
          if necessary to comply with legal obligations, resolve disputes, or enforce our agreements.
        </p>
      </section>

      <section>
        <h2>7. Security of Your Information</h2>
        <p>
          We implement reasonable security measures designed to protect your Personal Information from unauthorized access, 
          disclosure, alteration, or destruction. However, no security system is impenetrable, and we cannot guarantee the 
          absolute security of your information. You acknowledge that you provide your Personal Information at your own risk.
        </p>
      </section>

      <section>
        <h2>8. Your Choices Regarding Your Information</h2>
        <p>
          You have the right to request access to, correction of, or deletion of your Personal Information, subject to certain 
          limitations imposed by law. You may be able to exercise these rights by accessing your account settings or by 
          contacting us directly at privacy@boon.com. We will make reasonable efforts to accommodate your request, but we may 
          decline requests that are overly burdensome, violate the privacy of others, or conflict with our legal obligations.
        </p>
      </section>

      <section>
        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or update this Policy at any time. Any changes to this Policy 
          will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this 
          Policy periodically to stay informed about how we are protecting your Personal Information. Your continued use of 
          the Platform following any changes to this Policy constitutes your acceptance of the revised terms.
        </p>
      </section>

      <section>
        <h2>10. Contact Information</h2>
        <p>
          If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact 
          us at privacy@boon.com. We will endeavor to respond to your inquiries in a timely manner.
        </p>
      </section>

      <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <h2>Boon</h2>
      <p>Empowering community building and professional growth.</p>
    </div>
    
    <div className="footer-links">
      <div className="footer-column">
        <h3>Boon</h3>
        <a href="/about">About</a>
        <a href="/careers">Careers</a>
        {/* <a href="/blog">Blog</a> */}
      </div>
      <div className="footer-column">
        <h3>Resources</h3>
        <a href="/support">Support</a>
        <a href="/faq">FAQ</a>
        {/* <a href="/tutorials">Tutorials</a> */}

      </div>
    </div>
  </div>
  
  <div className="footer-bottom">
    <p>&copy; 2024 Boon. All Rights Reserved.</p>
    <div className="footer-bottom-links">
      <a href="/privacy">Privacy Policy</a>
      <a href="/tos">Terms of Service</a>
    </div>
    <div className="social-icons">
      <a href="#"><i className="fab fa-instagram"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>
    </div>
  );
}

export default PrivacyPolicy;
